import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCaretDown, faChevronLeft, faEye, faShare } from '@fortawesome/free-solid-svg-icons';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { Container } from 'react-bootstrap';
import SharePerson from './share_person';
import ShareTeam from './share_team';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
//
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getCookie } from 'cookies-next/lib';
import { LoaderProducts } from '../../containers/Dashboard';

import * as Sentry from '@sentry/nextjs';

export const SearchAutoC = styled.div`
    z-index: 99 !important;

    .jVORrT {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 0 0 0 16px;
        fill: grey;
    }

    .dYrfwz {
        min-height: 44px;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .dYrfwz > input {
        width: 100%;
        padding: 0 0 0 13px;
        border: none;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        font-size: inherit;
        font-family: inherit;
        color: #212121;
    }

    .dYrfwz > input::placeholder {
        color: grey;
        opacity: 1;
    }

    .dYrfwz > input::-webkit-input-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::-moz-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input:-ms-input-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::placeholder::-ms-input-placeholder {
        color: grey;
    }

    .jiMOeR {
        position: relative;
        height: 46px;
    }

    .jiMOeR .wrapper {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        border: 1px solid #dfe1e5;
        border-radius: 24px;
        background-color: white;
        color: #212121;
        font-size: 16px;
        font-family: Arial;
        z-index: 0;
    }

    .jiMOeR .wrapper:hover {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    .jiMOeR .wrapper:active {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    .jiMOeR .wrapper:focus-within {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    //
    //   .search {
    //
    //    margin-bottom: 50px;
    //  }
    //
    //.jiMOeR {
    //  position: relative;
    //  height: 46px;
    //}
    //  .cccCkg .search .wrapper {
    //    border-radius: 11px;
    //  }
    //  .jiMOeR .wrapper {
    //    position: absolute;
    //    display: -webkit-box;
    //    display: -webkit-flex;
    //    display: -ms-flexbox;
    //    display: flex;
    //    -webkit-flex-direction: column;
    //    -ms-flex-direction: column;
    //    flex-direction: column;
    //    width: 100%;
    //    border: 1px solid #dfe1e5;
    //    border-radius: 24px;
    //    background-color: white;
    //    color: #212121;
    //    font-size: 16px;
    //    font-family: Arial;
    //    z-index: 0;
    //  }
    //  .iAtgkS >.wrapper {
    //    overflow: hidden;
    //  }
    //  .cccCkg .search .wrapper >div {
    //    min-height: 36px !important;
    //  }
    //
    //
    //.dYrfwz {
    //  min-height: 44px;
    //  width: 100%;
    //  display: -webkit-box;
    //  display: -webkit-flex;
    //  display: -ms-flexbox;
    //  display: flex;
    //  -webkit-align-items: center;
    //  -webkit-box-align: center;
    //  -ms-flex-align: center;
    //  align-items: center;
    //}
    //  .dNmsIl .search-icon {
    //    display: block;
    //  }
    //  .jVORrT {
    //    -webkit-flex-shrink: 0;
    //    -ms-flex-negative: 0;
    //    flex-shrink: 0;
    //    margin: 0 0 0 16px;
    //    fill: grey;
    //  }
    //  .cccCkg.share input {
    //    width: 100%;
    //    border-radius: 11px;
    //    font-size: 16px;
    //    padding: 5px;
    //    padding-left: 10px;
    //  }
    //
    //  .cccCkg .search .wrapper .clear-icon {
    //    margin: 0px 10px 0 0;
    //  }
    //  .cccCkg .search .wrapper >div {
    //    min-height: 36px !important;
    //  }
    //  .cccCkg .search .wrapper >div {
    //    min-height: 36px !important;
    //  }
    //  .cccCkg .search-autocomplete {
    //    border-radius: 22px;
    //  }
    //  .cccCkg .search-autocomplete {
    //    border-radius: 22px;
    //  }
    //  .cccCkg .search-autocomplete-icon {
    //    width: 15%;
    //    float: left;
    //  }
    //  .cccCkg .search-autocomplete-description {
    //    width: 75%;
    //    float: left;
    //  }
    //  .cccCkg.share img {
    //    width: 48px;
    //    height: 48px;
    //    border-radius: 50%;
    //  }
    //  .cccCkg span {
    //    font-weight: 300;
    //    font-size: 18px;
    //  }
    //  .cccCkg span {
    //    font-weight: 300;
    //    font-size: 18px;
    //  }
    //
    //
    //  .cGfobY li {
    //    padding-left: 15px;
    //    padding-top: 7px;
    //    padding-bottom: 3px;
    //    -webkit-transition: all 0.3s ease;
    //    transition: all 0.3s ease;
    //  }
    //.bcxAyf .clear-icon.hidden {
    //  opacity: 0;
    //}
    //.dYrfwz > input {
    //  width: 100%;
    //  padding: 0 0 0 13px;
    //  border: none;
    //  transition: all 0.3s ease;
    //  outline: none;
    //  background-color: rgba(0,0,0,0);
    //  font-size: inherit;
    //  font-family: inherit;
    //  color: #212121;
    //}

    //////////////////////////

    .search-icon {
        display: block;
    }

    .clear-icon {
        opacity: 0.5;
        transition: all 0.3s ease;

        &.hidden {
            opacity: 0;
        }
    }

    /*    > .wrapper {
        overflow: hidden;
    }*/

    .search-autoc-people {
        //z-index:9999;

        &.hide {
            display: none;
        }
    }

    li {
        padding-left: 15px;
        padding-top: 7px;
        padding-bottom: 3px;
        transition: all 0.3s ease;

        &:hover {
            background: lightgrey;
        }
    }

    ul {
        list-style-type: none;
        width: 100%;
        height: fit-content;
        max-height: 300px;
        display: inline-grid;
        margin: 0;
        padding: 0;
    }
`;

export default function ShareDialog({ props: any, changeShareShow, slugState, typeEditor, showShareDialog, showShareDialogTransition, disableLoad }) {
    const [activeTab, setActiveTab] = useState('version');

    const [emailItem, setEmailItem] = useState([]);

    const [useEmailItem, changeUseEmailItem] = useState(false);

    const [itemsSearch, setItemsSearch] = useState([
        {
            id: 0,
            name: '',
            email: '',
            image: '',
        },
    ]);

    const [itemsSearchOriginal, setItemsSearchOriginal] = useState([]);

    const [versionSharePeople, setVersionSharePeople] = useState([]);
    const [productSharePeople, setProductSharePeople] = useState([]);

    const [versionOthersSharePeople, setOthersVersionSharePeople] = useState([]);
    const [productOthersSharePeople, setOthersProductSharePeople] = useState([]);

    const [othersVersions, setOthersVersion] = useState(false);
    const [othersProducts, setOthersProduct] = useState(false);

    const [publicLink, setPublicLink] = useState(null);
    const [currentVersionShare, setCurrentVersionShare] = useState(0);
    const [currentProductName, setCurrenProductName] = useState('Product Name');

    const [shareTeamName, setShareTeamName] = useState(null);

    const [shareTeamLogo, setShareTeamLogo] = useState(null);
    const [shareTeamRole, setShareTeamRole] = useState(null);

    const [shareTeamRoleVersion, setShareTeamRoleVersion] = useState(null);

    const [shareVersionUUID, setShareVersionUUID] = useState(null);
    const [shareProductUUID, setShareProductUUID] = useState(null);

    const [customShareMessage, setCustomShareMessage] = useState('');

    const [shareResponse, setShareResponse] = useState('');
    const sendShareInvites = type => {
        var shareUUID;

        if (type === 'version') {
            shareUUID = shareVersionUUID;
        } else {
            shareUUID = shareProductUUID;
        }

        var userShare = [];

        shareInvitePeople.map((value, index) => {
            userShare.push({ uuid: value.id, email: value.email, permissions: value.permission });
        });

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareUUID + '/members', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
            body: JSON.stringify({
                users: userShare,
                message: customShareMessage,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                if (data.message === undefined) {
                    // shareResponse

                    setActiveTab('share_response_tab');
                    setShareInvitePeople([]);
                    setShareResponse(data.success);

                    if (type === 'version') {
                        setVersionSharePeople([...data.data.team.members]);
                    } else {
                        setProductSharePeople([...data.data.team.members]);
                    }
                }
            });
    };

    const [inputAutocompleteValue, setInputAutocompleteValue] = useState('');

    const closeAndClearInput = () => {
        setItemsSearch(itemsSearchOriginal);
        setShowFocusedPeopleAutoC(false);
        setInputAutocompleteValue('');
        changeUseEmailItem(false);
    };

    const handleOnSearchNew = function (e: React.FormEvent<HTMLInputElement>) {
        setInputAutocompleteValue(e.currentTarget.value);

        var firstResultsOriginal = [];
        var string = e.currentTarget.value;
        Object.keys(itemsSearchOriginal).map(key => {
            if (itemsSearchOriginal[key]['email'].includes(e.currentTarget.value) || itemsSearchOriginal[key]['name'].includes(e.currentTarget.value)) {
                firstResultsOriginal.push(itemsSearchOriginal[key]);
            }
        });

        setItemsSearch(firstResultsOriginal);

        if (firstResultsOriginal.length === 0 && e.currentTarget.value.length > 3) {
            changeUseEmailItem(true);
            setEmailItem([
                {
                    name: string,
                    image: '/images/share/Person1.png',
                    id: 'unset ',
                    email: string,
                    team_member: false,
                    permission: 'edit',
                },
            ]);
        } else {
            changeUseEmailItem(false);
        }
        if (useEmailItem) {
            setEmailItem([
                {
                    name: string,
                    image: '/images/share/Person1.png',
                    id: 'unset ',
                    email: string,
                    team_member: false,
                    permission: 'edit',
                },
            ]);
        }
    };

    const resetShareInvite = location => {
        switchShareMessage(false);
        setShareInvitePeople([]);
        setActiveTab(location);
        //   invite
    };

    const [loadProductsShow, setLoadProductsShowVariable] = useState(true);
    const [loadTransitionProductsShow, setLoadTransitionProductsShow] = useState(true);

    const setLoadProductsShow = variable => {
        if (variable === true) {
            setLoadProductsShowVariable(variable);
            setLoadTransitionProductsShow(true);
        } else {
            setTimeout(function () {
                setLoadTransitionProductsShow(false);
                setTimeout(function () {
                    setLoadProductsShowVariable(variable);
                }, 500);
            }, 300);
        }
    };

    const [showFocusedPeopleAutoC, setShowFocusedPeopleAutoC] = useState(false);

    const handleOnFocusNew = result => {
        setShowFocusedPeopleAutoC(true);
    };


    const selectAutocompleteVersion = item => {

        if (activeTab !== 'share_confirm_version') {
            setActiveTab('share_confirm_version');
        }

        var elementInArray = false;

        shareInvitePeople.map(invitePeople => {
            if (item.id === invitePeople.id) {
                elementInArray = true;
            }
        });

        //   newArrayPeople.push(item);
        if (!elementInArray) {
            setShareInvitePeople([...shareInvitePeople, item]);
        }

        setShowFocusedPeopleAutoC(false);
        setItemsSearch(itemsSearchOriginal);
        changeUseEmailItem(false);
        setInputAutocompleteValue('');

    };

    const [shareInvitePeople, setShareInvitePeople] = useState([]);

    const [shareMessage, switchShareMessage] = useState(false);

    const [publicLinkOpened, setPublicLinkOpened] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = event => {
            if (!ref.current?.contains(event.target)) {
                //     alert("Outside Clicked.");
                setPublicLinkOpened(false);
            }
        };

        window.addEventListener('mousedown', handleOutSideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick);
        };
    }, [ref]);
    const changePublicLink = newPublic => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareVersionUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
            body: JSON.stringify({
                team_permissions: 'view',
                public_link: newPublic,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareVersionUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                if (data.message === undefined) {
                    if (!newPublic) {
                        setPublicLink(null);
                    } else {
                        setPublicLink(data.data.public_link);
                    }

                    //  setPublicLink()
                }
            });
    };

    const changeTeamRole = (newRole, typeShare) => {

        var shareUUID;

        if (shareProductUUID !== null) {
            shareUUID = shareProductUUID;
        }
        if (shareVersionUUID !== null) {
            shareUUID = shareVersionUUID;
        }

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify({
                team_permissions: newRole,
                public_link: publicLink === null ? false : true,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                if (data.message === undefined) {
                    if (typeShare === 'version') {
                        setShareTeamRoleVersion(newRole);
                        setVersionSharePeople([...data.data.team.members]);
                    } else {
                        setShareTeamRole(newRole);
                        setProductSharePeople(data.data.team.members);
                    }
                }
            });
    };

    const [shareVersionAutocomplete, setShareVersionAutocomplete] = useState([]);

    const loadShareDataAPI = () => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'editor/' + slugState, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('editor/' + slugState + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                setCurrentVersionShare(data.product.version);
                setCurrenProductName(data.product.name);

                if (data.share.product !== null) {
                    setShareProductUUID(data.share.product.uuid);

                    setShareTeamRole(data.share.product.team_permissions);
                    setProductSharePeople(data.share.product.team.members);
                    setShareTeamName(data.share.product.team.name);
                    setShareTeamLogo(data.share.product.team.logo);

                    if (data.share.product.other_members.length > 0) {
                        setOthersProduct(true);
                        setOthersProductSharePeople(data.share.product_version.other_members);
                    }

                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + data.share.product.uuid + '/autocomplete', {
                        method: 'GET',
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: 'Bearer ' + getCookie('userTeam'),
                        },
                    })
                        .then(response => response.json())
                        .then(function (data2) {
                            if (data2.status !== 200) {
                                Sentry.captureException('share/' + data.share.product.uuid + '/autocomplete' + ': ' + data2.message + ' : ' + getCookie('userMail'));
                            }
                            var newArrayAutocomplete = [];

                            {
                                data2.map((value, index) => {
                                    newArrayAutocomplete.push({
                                        id: value.user.uuid,
                                        name: value.user.full_name,
                                        email: value.user.email,
                                        team_member: value.team_member,
                                        image: value.user.avatar,
                                        permission: 'edit',
                                    });
                                });
                            }
                            setItemsSearch(newArrayAutocomplete);
                            setItemsSearchOriginal(newArrayAutocomplete);

                        });
                }
                if (data.share.product_version !== null) {
                    setShareVersionUUID(data.share.product_version.uuid);
                    setVersionSharePeople(data.share.product_version.team.members);
                    setShareTeamRoleVersion(data.share.product_version.team_permissions);
                    if (data.share.product_version.public_link !== null) {
                        setPublicLink(data.share.product_version.public_link);
                    }
                    if (data.share.product_version.other_members.length > 0) {
                        setOthersVersion(true);

                        // versionOthersSharePeople
                        setOthersVersionSharePeople(data.share.product_version.other_members);
                    }
                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + data.share.product_version.uuid + '/autocomplete', {
                        method: 'GET',
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: 'Bearer ' + getCookie('userTeam'),
                        },
                    })
                        .then(response => response.json())
                        .then(function (data2) {
                            if (data2.status !== 200) {
                                Sentry.captureException('share/' + data.share.product_version.uuid + '/autocomplete' + ': ' + data2.message + ' : ' + getCookie('userMail'));
                            }
                            setShareVersionAutocomplete(data2);
                        });
                }

                setLoadProductsShow(false);
            });
    };

    useEffect(() => {
        if (slugState) {
            loadShareDataAPI();
        }
    }, [slugState]);

    const [copiedText, setCopiedText] = useState('Copy link');

    const copyPublicLink = () => {
        navigator.clipboard.writeText(publicLink);
        setCopiedText('Copied !');

        setTimeout(function () {
            setCopiedText('Copy link');
        }, 1000);
    };
    const closeModalOutsideClick = e => {

        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeShareShow(false);
            setShowFocusedPeopleAutoC(false);
            setInputAutocompleteValue('');
            setItemsSearch(itemsSearchOriginal);
            changeUseEmailItem(false);
        }
    };
    const closeModalOutsideClickAutocomplete = e => {

        if (e.target.getAttribute('data-closemodalautocomplete') === 'true') {
            setShowFocusedPeopleAutoC(false);
            setInputAutocompleteValue('');
            setItemsSearch(itemsSearchOriginal);
            changeUseEmailItem(false);
        }
    };

    return (
        <ModalsWrapper
            className={`  ${showShareDialogTransition ? 'showTransition' : 'hideTransition'} ${!showShareDialog ? 'hiddeComplete' : ''}`}
            data-closemodal={true}
            onClick={e => closeModalOutsideClick(e)}
            // data-closemodalautocomplete={true}
            // onClick={(e)=> closeModalOutsideClickAutocomplete(e)}
        >
            <ModalWindow className={'share'} data-closemodalautocomplete={true} onClick={e => closeModalOutsideClickAutocomplete(e)}>
                <div className={'modal-header'} data-closemodalautocomplete={true}>
                    {activeTab === 'product' || activeTab === 'version' ? <h4>Share</h4> : <h4>Sharing product</h4>}
                </div>

                <div className={`${activeTab === 'product' || activeTab === 'version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <Container data-closemodalautocomplete={true}>
                            <div className="align-center text-center mb-xxs-30" data-closemodalautocomplete={true}>
                                <ul className="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
                                    <li className="nav-item" role="typography">
                                        <a data-closemodalautocomplete={true} onClick={() => setActiveTab('version')} className={`nav-link ${activeTab === 'version' ? 'active' : ''} `}>
                                            Version
                                        </a>
                                    </li>

                                    <li className="nav-item" role="typography">
                                        <a data-closemodalautocomplete={true} onClick={() => setActiveTab('product')} className={`nav-link ${activeTab === 'product' ? 'active' : ''} `}>
                                            Product
                                        </a>
                                    </li>
                                </ul>

                                {activeTab === 'product' ? (
                                    <span data-closemodalautocomplete={true} className={'infoText'}>
                                        <b>All versions </b> of <b>Product Name</b>
                                        <br />
                                        are shared with following users:
                                    </span>
                                ) : (
                                    <span data-closemodalautocomplete={true} className={'infoText'}>
                                        <b>Current version ({currentVersionShare})</b> of <b>{currentProductName}</b>
                                        <br />
                                        is shared with following users:
                                    </span>
                                )}
                            </div>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_confirm' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30">
                                <button className={'backButton'} onClick={() => setActiveTab('version')}>
                                    <FontAwesomeIcon icon={faChevronLeft} className={'downIcon'} />
                                </button>
                                <span className={'infoText'}>
                                    <b>All versions</b> of <b>{currentProductName}</b>
                                    <br />
                                    are shared with following users:
                                </span>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <div className="container" data-closemodalautocomplete={true}>
                            <SearchAutoC className="sc-dcJsrY jiMOeR search search-autoc">
                                <div className="wrapper">
                                    <div className="sc-fqkvVR dYrfwz">
                                        <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg>

                                        <input
                                            type="text"
                                            spellCheck="false"
                                            placeholder=""
                                            data-test="search-input"
                                            onChange={handleOnSearchNew}
                                            value={inputAutocompleteValue}
                                            onFocus={handleOnFocusNew}
                                        />
                                        <div className={`sc-eqUAAy ewTnKk clear-icon ${inputAutocompleteValue.length > 0 ? '' : 'hidden'} `} onClick={closeAndClearInput}>
                                            <svg width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className={`sc-gEvEer ffXrxx search-autoc-people ${showFocusedPeopleAutoC ? 'active' : 'hide'} `}>
                                        <div className="line"></div>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {useEmailItem ? (
                                                <>
                                                    {emailItem.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.uuid} key={value.uuid} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {itemsSearch.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.id} key={value.id} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </SearchAutoC>
                        </div>
                    </section>

                    <section className="page-section small-section">
                        <Container>
                            <h3>Team</h3>

                            <div className={'sharePersonsMyTeamContent'}>
                                {shareInvitePeople.map((value, index) => {
                                    if (value.team_member) {
                                        return (
                                            <SharePerson
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                invite={true}
                                                userUUID={value.id}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}

                                {shareInvitePeople.map((value, index) => {
                                    //  newArrayNotif.push( data[key]);
                                    if (!value.team_member) {
                                        return (
                                            <SharePerson
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                userUUID={value.id}
                                                invite={true}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section others-section">
                        <Container>
                            {shareInvitePeople.map((value, index) => {
                                //  newArrayNotif.push( data[key]);
                                if (!value.team_member) {
                                    return (
                                        <SharePerson
                                            sharePersonIcon={value.image}
                                            sharePersonName={value.name}
                                            sharePersonEmail={value.email}
                                            sharePersonRole={value.permission}
                                            userUUID={value.id}
                                            invite={true}
                                            shareProductUUID={shareProductUUID}
                                            shareVersionUUID={shareVersionUUID}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                }
                            })}
                        </Container>
                    </section>

                    <section className="page-section small-section">
                        <Container className={'shareInvite'}>
                            <h3 style={{ marginBottom: '0' }}> Message</h3>
                            <div className={'inviteMessage'}>
                                <label className="form-switch" style={{ width: '40px', float: 'left' }}>
                                    <input type="checkbox" checked={shareMessage ? true : false} onChange={() => switchShareMessage(shareMessage ? false : true)} />
                                    <i onClick={() => switchShareMessage(shareMessage ? false : true)} style={{ top: '0px', left: '-37px' }}></i>
                                </label>
                                <span> Add invite message</span>
                            </div>
                            <div>{shareMessage ? <textarea></textarea> : null}</div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'borderBlue btn-circle '} onClick={() => resetShareInvite('product')}>
                                Cancel
                            </GlobalButtonFinal>

                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => sendShareInvites('product')}>
                                <FontAwesomeIcon className={'iconBtn'} icon={faShare} /> Share
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_confirm_version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30">
                                <button className={'backButton'} onClick={() => resetShareInvite('version')}>
                                    <FontAwesomeIcon icon={faChevronLeft} className={'downIcon'} />
                                </button>
                                {/*Sharing product*/}
                                <span className={'infoText'}>
                                    <b>All versions</b> of <b>{currentProductName}</b>
                                    <br />
                                    are shared with following users:
                                </span>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <div className="container" data-closemodalautocomplete={true}>
                            <SearchAutoC className="sc-dcJsrY jiMOeR search search-autoc">
                                <div className="wrapper">
                                    <div className="sc-fqkvVR dYrfwz">
                                        <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg>

                                        <input
                                            type="text"
                                            spellCheck="false"
                                            placeholder=""
                                            data-test="search-input"
                                            onChange={handleOnSearchNew}
                                            value={inputAutocompleteValue}
                                            onFocus={handleOnFocusNew}
                                        />
                                        <div className={`sc-eqUAAy ewTnKk clear-icon ${inputAutocompleteValue.length > 0 ? '' : 'hidden'} `} onClick={closeAndClearInput}>
                                            <svg width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className={`sc-gEvEer ffXrxx search-autoc-people ${showFocusedPeopleAutoC ? 'active' : 'hide'} `}>
                                        <div className="line"></div>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {useEmailItem ? (
                                                <>
                                                    {emailItem.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.uuid} key={value.uuid} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {itemsSearch.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.id} key={value.id} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </SearchAutoC>
                        </div>
                    </section>

                    <section className="page-section small-section">
                        <Container>
                            <h3>Team</h3>

                            <div className={'sharePersonsMyTeamContent'}>
                                {shareInvitePeople.map((value, index) => {
                                    if (value.team_member) {
                                        return (
                                            <SharePerson
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                userUUID={value.id}
                                                invite={true}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section others-section">
                        <Container>
                            {shareInvitePeople.map((value, index) => {
                                if (!value.team_member) {
                                    return (
                                        <SharePerson
                                            sharePersonIcon={value.image}
                                            sharePersonName={value.name}
                                            sharePersonEmail={value.email}
                                            sharePersonRole={value.permission}
                                            userUUID={value.id}
                                            invite={true}
                                            shareVersionUUID={shareVersionUUID}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                }
                            })}
                        </Container>
                    </section>

                    <section className="page-section small-section">
                        <Container className={'shareInvite'}>
                            <h3 style={{ marginBottom: '0' }}> Message</h3>
                            <div className={'inviteMessage'}>
                                <label className="form-switch" style={{ width: '40px', float: 'left' }}>
                                    <input type="checkbox" checked={shareMessage ? true : false} onChange={() => switchShareMessage(shareMessage ? false : true)} />
                                    <i onClick={() => switchShareMessage(shareMessage ? false : true)} style={{ top: '0px', left: '-37px' }}></i>
                                </label>
                                <span> Add invite message</span>
                            </div>
                            <div>{shareMessage ? <textarea></textarea> : null}</div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'borderBlue btn-circle '} onClick={() => resetShareInvite('version')}>
                                Cancel
                            </GlobalButtonFinal>

                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => sendShareInvites('version')}>
                                <FontAwesomeIcon className={'iconBtn'} icon={faShare} /> Share
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_response_tab' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30">
                                <h4>{shareResponse}</h4>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => resetShareInvite('version')}>
                                Close
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <div className="container" data-closemodalautocomplete={true}>
                            <SearchAutoC className="sc-dcJsrY jiMOeR search search-autoc">
                                <div className="wrapper">
                                    <div className="sc-fqkvVR dYrfwz">
                                        <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg>

                                        <input
                                            type="text"
                                            spellCheck="false"
                                            placeholder=""
                                            data-test="search-input"
                                            onChange={handleOnSearchNew}
                                            value={inputAutocompleteValue}
                                            onFocus={handleOnFocusNew}
                                        />
                                        <div className={`sc-eqUAAy ewTnKk clear-icon ${inputAutocompleteValue.length > 0 ? '' : 'hidden'} `} onClick={closeAndClearInput}>
                                            <svg width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className={`sc-gEvEer ffXrxx search-autoc-people ${showFocusedPeopleAutoC ? 'active' : 'hide'} `}>
                                        <div className="line"></div>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {useEmailItem ? (
                                                <>
                                                    {emailItem.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.uuid} key={value.uuid} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {itemsSearch.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.id} key={value.id} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </SearchAutoC>
                        </div>
                    </section>

                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <Container data-closemodalautocomplete={true}>
                            <h3 data-closemodalautocomplete={true}>Team</h3>

                            <div className={'sharePersonsMyTeamContent'} data-closemodalautocomplete={true}>
                                <ShareTeam
                                    data-closemodalautocomplete={true}
                                    shareTeamIcon={shareTeamLogo}
                                    shareTeamName={shareTeamName}
                                    shareTeamRole={shareTeamRoleVersion}
                                    changeTeamRole={changeTeamRole}
                                    typeShare={'version'}
                                >
                                    {versionSharePeople.map((value, index) => {
                                        if (value.team_default) {
                                            return (
                                                <SharePerson
                                                    data-closemodalautocomplete={true}
                                                    sharePersonIcon={value.user.avatar}
                                                    sharePersonName={value.user.full_name}
                                                    sharePersonEmail={value.user.email}
                                                    sharePersonRole={value.permissions}
                                                    userUUID={value.user.uuid}
                                                    shareVersionUUID={shareVersionUUID}
                                                    usrteam={getCookie('userTeam')}
                                                />
                                            );
                                        }
                                    })}
                                </ShareTeam>

                                {versionSharePeople.map((value, index) => {
                                    if (!value.team_default) {
                                        return (
                                            <SharePerson
                                                data-closemodalautocomplete={true}
                                                sharePersonIcon={value.user.avatar}
                                                sharePersonName={value.user.full_name}
                                                sharePersonEmail={value.user.email}
                                                userUUID={value.user.uuid}
                                                sharePersonRole={value.permissions}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    {othersVersions ? (
                        <section className="page-section small-section others-section" data-closemodalautocomplete={true}>
                            <Container data-closemodalautocomplete={true}>
                                <h3 data-closemodalautocomplete={true}>Others</h3>

                                {versionOthersSharePeople.map((value, index) => {
                                    //  newArrayNotif.push( data[key]);

                                    return (
                                        <SharePerson
                                            sharePersonIcon={value.user.avatar}
                                            sharePersonName={value.user.full_name}
                                            sharePersonEmail={value.user.email}
                                            userUUID={value.user.uuid}
                                            sharePersonRole={value.permissions}
                                            shareVersionUUID={shareVersionUUID}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                })}
                            </Container>
                        </section>
                    ) : null}

                    <section className="page-section small-section public-section" data-closemodalautocomplete={true} style={othersProducts ? {} : { marginTop: '30px' }}>
                        <Container data-closemodalautocomplete={true}>
                            <h3 data-closemodalautocomplete={true}>Public link</h3>

                            <div className={'public_link'}>
                                <div className={'publicLink'}>
                                    <div className={'personIcon'}>
                                        {publicLink !== null ? <img data-closemodalautocomplete={true} style={{ maxWidth: '100%' }} src={'/images/share/Globe.png'} /> : null}
                                    </div>
                                    <div data-closemodalautocomplete={true} className={'personName'}>
                                        <p data-closemodalautocomplete={true}>{publicLink !== null ? <b> Anyone with this link can view</b> : <b> Public link is disabled</b>}</p>
                                        {publicLink !== null ? <p data-closemodalautocomplete={true}> {publicLink}</p> : <p> Public link is disabled</p>}
                                    </div>
                                    <div data-closemodalautocomplete={true} className={'personActions'}>
                                        {publicLink !== null ? (
                                            <button data-closemodalautocomplete={true} onClick={() => setPublicLinkOpened(true)}>
                                                Viewer <FontAwesomeIcon icon={faCaretDown} className={'downIcon'} />
                                            </button>
                                        ) : (
                                            <button data-closemodalautocomplete={true} onClick={() => setPublicLinkOpened(true)}>
                                                Disabled <FontAwesomeIcon icon={faCaretDown} className={'downIcon'} />
                                            </button>
                                        )}

                                        <div className={`${publicLinkOpened ? 'active personPopup' : 'personPopup'} `} ref={ref}>
                                            <ul>
                                                <li data-closemodalautocomplete={true} onClick={() => changePublicLink(true)} className={`${publicLink !== null ? 'active' : ''} `}>
                                                    <FontAwesomeIcon icon={faEye} className={'downIcon'} />
                                                    <span>Viewer</span>
                                                </li>
                                                <li data-closemodalautocomplete={true} onClick={() => changePublicLink(false)} className={`${publicLink === null ? 'active' : ''} `}>
                                                    <FontAwesomeIcon icon={faBan} className={'downIcon'} />
                                                    <span>Disabled</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section" data-closemodalautocomplete={true}>
                        <Container data-closemodalautocomplete={true}>
                            {publicLink !== null ? (
                                <GlobalButtonFinal
                                    style={{ width: '164px' }}
                                    // onMouseLeave={() => setCopiedText("Copy link")}
                                    className={'borderBlue btn-circle'}
                                    onClick={() => copyPublicLink()}
                                >
                                    <FontAwesomeIcon icon={faLink} /> {copiedText}
                                </GlobalButtonFinal>
                            ) : null}

                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => changeShareShow(false)}>
                                Done
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'product' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}>
                        <div className="container" data-closemodalautocomplete={true}>
                            <SearchAutoC className="sc-dcJsrY jiMOeR search search-autoc">
                                <div className="wrapper">
                                    <div className="sc-fqkvVR dYrfwz">
                                        <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg>

                                        <input
                                            type="text"
                                            spellCheck="false"
                                            placeholder=""
                                            data-test="search-input"
                                            onChange={handleOnSearchNew}
                                            value={inputAutocompleteValue}
                                            onFocus={handleOnFocusNew}
                                        />
                                        <div className={`sc-eqUAAy ewTnKk clear-icon ${inputAutocompleteValue.length > 0 ? '' : 'hidden'} `} onClick={closeAndClearInput}>
                                            <svg width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className={`sc-gEvEer ffXrxx search-autoc-people ${showFocusedPeopleAutoC ? 'active' : 'hide'} `}>
                                        <div className="line"></div>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {useEmailItem ? (
                                                <>
                                                    {emailItem.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.uuid} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {itemsSearch.map((value, index) => {
                                                        return (
                                                            <li className="selected" data-test="result" data-uuid={value.id} key={value.id} onClick={() => selectAutocompleteVersion(value)}>
                                                                <div className={'search-autocomplete'} key={'asdasd'}>
                                                                    <div className={'search-autocomplete-icon'}>
                                                                        <img
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                                maxWidth: '100%',
                                                                            }}
                                                                            src={value.image}
                                                                        />
                                                                    </div>
                                                                    <div className={'search-autocomplete-description'}>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.name}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {value.email}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </SearchAutoC>
                        </div>
                    </section>

                    <section className="page-section small-section">
                        <Container>
                            <h3>Team</h3>

                            <div className={'sharePersonsMyTeamContent'}>
                                <ShareTeam shareTeamIcon={shareTeamLogo} shareTeamName={shareTeamName} shareTeamRole={shareTeamRole} changeTeamRole={changeTeamRole} typeShare={'product'}>
                                    {productSharePeople.map((value, index) => {
                                        if (value.team_default) {
                                            return (
                                                <SharePerson
                                                    sharePersonIcon={value.user.avatar}
                                                    sharePersonName={value.user.full_name}
                                                    sharePersonEmail={value.user.email}
                                                    sharePersonRole={value.permissions}
                                                    userUUID={value.user.uuid}
                                                    shareProductUUID={shareProductUUID}
                                                    usrteam={getCookie('userTeam')}
                                                />
                                            );
                                        }
                                    })}
                                </ShareTeam>

                                {productSharePeople.map((value, index) => {
                                    if (!value.team_default) {
                                        return (
                                            <SharePerson
                                                sharePersonIcon={value.user.avatar}
                                                sharePersonName={value.user.full_name}
                                                sharePersonEmail={value.user.email}
                                                userUUID={value.user.uuid}
                                                sharePersonRole={value.permissions}
                                                shareProductUUID={shareProductUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    {othersProducts ? (
                        <section className="page-section small-section others-section">
                            <Container>
                                <h3>Others</h3>

                                {productOthersSharePeople.map((value, index) => {
                                    return (
                                        <SharePerson
                                            sharePersonIcon={value.user.avatar}
                                            sharePersonName={value.user.full_name}
                                            sharePersonEmail={value.user.email}
                                            userUUID={value.user.uuid}
                                            sharePersonRole={value.permissions}
                                            shareProductUUID={shareProductUUID}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                })}
                            </Container>
                        </section>
                    ) : null}

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => changeShareShow(false)}>
                                Done
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <LoaderProducts className={`share-products-loader ${loadTransitionProductsShow ? 'showTransition' : 'hideTransition'} ${!loadProductsShow ? 'hiddeComplete' : ''} `}>
                    <img src={'/logoAnimate.svg'} />
                </LoaderProducts>

                <div style={{ height: '0', overflow: 'hidden' }}>
                    <ReactSearchAutocomplete />
                </div>
            </ModalWindow>
        </ModalsWrapper>
    );
}
