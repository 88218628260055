import { defineMessages, FormattedMessage } from 'react-intl';
import { EditorTopActionsBarWindow } from '../components/app/pages/editor/styles/EditorStyles';
import React from 'react';

const definedMessages = defineMessages({
    HELLO: {
        id: 'HELLO',
        defaultMessage: 'Hello',
    },
    mainmenu: {
        basicplan: {
            id: 'mainmenu.basicplan',
            defaultMessage: 'BASIC PLAN',
        },
        profile_account: {
            id: 'mainmenu.profile_account',
            defaultMessage: 'Profile',
        },
        teams: {
            id: 'mainmenu.teams',
            defaultMessage: 'Teams',
        },
        preferences: {
            id: 'mainmenu.preferences',
            defaultMessage: 'Preferences',
        },
        help: {
            id: 'mainmenu.help',
            defaultMessage: 'Help',
        },
        apps: {
            id: 'mainmenu.apps',
            defaultMessage: 'Apps',
        },
        sign_out: {
            id: 'mainmenu.sign_out',
            defaultMessage: 'Sign Out',
        },
        back: {
            id: 'mainmenu.back',
            defaultMessage: 'Back',
        },
        create_new_team: {
            id: 'mainmenu.create_new_team',
            defaultMessage: 'Create new team',
        },
    },
    editor: {
        add_comment: {
            id: 'editor.add_comment',
            defaultMessage: 'Add comment',
        },
        comments: {
            id: 'editor.comments',
            defaultMessage: 'Comments',
        },
        new_design: {
            id: 'editor.new_design',
            defaultMessage: 'New design',
        },
        edit: {
                id: 'editor.edit',
                defaultMessage: 'Edit',
        },
        mode: {
            id: 'editor.mode',
            defaultMessage: 'Mode',
        },
        comment: {
            id: 'editor.comment',
            defaultMessage: 'Comment',
        },
        view: {
            id: 'editor.view',
            defaultMessage: 'View',
        },
        ar: {
            id: 'editor.ar',
            defaultMessage: 'AR View',
        },
        background: {
            id: 'editor.background',
            defaultMessage: 'Background',
        },
        dining_room: {
            id: 'editor.dining_room',
            defaultMessage: 'Dining room',
        },
        living_room: {
            id: 'editor.living_room',
            defaultMessage: 'Living room',
        },
        kitchen: {
            id: 'editor.kitchen',
            defaultMessage: 'Kitchen',
        },
        bathroom: {
            id: 'editor.bathroom',
            defaultMessage: 'Bathroom',
        },
        store_shelf: {
            id: 'editor.store_shelf',
            defaultMessage: 'Store - shelf',
        },
        nature: {
            id: 'editor.nature',
            defaultMessage: 'Nature',
        },
        scene_options: {
            id: 'editor.scene_options',
            defaultMessage: 'Scene options',
        },
        scenes: {
            id: 'editor.scenes',
            defaultMessage: 'Scenes',
        },
        color: {
            id: 'editor.color',
            defaultMessage: 'Color',
        },
        other_settings: {
            id: 'editor.other_settings',
            defaultMessage: 'Other settings',
        },
        rulers: {
            id: 'editor.rulers',
            defaultMessage: 'Rulers',
        },
        grid: {
            id: 'editor.grid',
            defaultMessage: 'Grid',
        },
        rendering: {
            id: 'editor.rendering',
            defaultMessage: 'Rendering',
        },
        front: {
            id: 'editor.front',
            defaultMessage: 'Front',
        },
        up: {
            id: 'editor.up',
            defaultMessage: 'Up',
        },
        front_up: {
            id: 'editor.front_up',
            defaultMessage: 'Front + Up',
        },
        custom: {
            id: 'editor.custom',
            defaultMessage: 'Custom',
        },
        version: {
            id: 'editor.version',
            defaultMessage: 'Version',
        },
        help: {
            whats_new: {
                id: 'editor.help.whats_new',
                defaultMessage: 'Whats new ?'
            },
            tutorials: {
                id: 'editor.help.tutorials',
                defaultMessage: 'Tutorials'
            },
            help_center: {
                id: 'editor.help.help_center',
                defaultMessage: 'Help center'
            },
        },
    },
    notifications: {
        nonew: {
            id: 'notifications.nonew',
            defaultMessage: 'There are no new notifications.',
        },
    },
    sidemenu: {
        products: {
            id: 'sidemenu.products',
            defaultMessage: 'Products',
        },
        presentations: {
            id: 'sidemenu.presentations',
            defaultMessage: 'Presentations',
        },
        packshots: {
            id: 'sidemenu.packshots',
            defaultMessage: 'Packshots',
        },
    },
    products: {
        header: {
            id: 'products.header',
            defaultMessage: 'My products',
        },
        modal: {
            addnewclient: {
                id: 'products.modal.addnewclient',
                defaultMessage: 'Add new client',
            },
            addnewbrand: {
                id: 'products.modal.addnewcbrand',
                defaultMessage: 'Add new brand',
            },
            addnewedition: {
                id: 'products.modal.addnewedition',
                defaultMessage: 'Add new edition',
            },
            assigntoclient: {
                id: 'products.modal.assigntoclient',
                defaultMessage: 'Assign to a client ?',
            },
            assigntobrand: {
                id: 'products.modal.assigntobrand',
                defaultMessage: 'Assign to a brand ?',
            },

            clientname: {
                id: 'products.modal.clientname',
                defaultMessage: 'Set client name',
            },
            brandname: {
                id: 'products.modal.brandname',
                defaultMessage: 'Brand name',
            },
            editionname: {
                id: 'products.modal.editionname',
                defaultMessage: 'Edition name',
            },

            dontassign: {
                id: 'products.modal.dontassign',
                defaultMessage: 'Dont assign',
            },

            icon: {
                id: 'products.modal.icon',
                defaultMessage: 'And upload icon',
            },
        },
        descriptions: {
            versions: {
                id: 'products.descriptions.versions',
                defaultMessage: 'Versions',
            },
            custom: {
                id: 'products.descriptions.custom',
                defaultMessage: 'Custom',
            },
            open: {
                id: 'products.descriptions.open',
                defaultMessage: 'Open',
            },
            duplicate: {
                id: 'products.descriptions.duplicate',
                defaultMessage: 'Duplicate',
            },
            trash: {
                id: 'products.descriptions.trash',
                defaultMessage: 'Move to trash',
            },
            export: {
                id: 'products.descriptions.export',
                defaultMessage: 'Export',
            },
            publish: {
                id: 'products.descriptions.publish',
                defaultMessage: 'Publish',
            },
            information: {
                id: 'products.descriptions.information',
                defaultMessage: 'Information',
            },
            deletequestion: {
                id: 'products.descriptions.deletequestion',
                defaultMessage: 'Are you sure that you want to delete this product ?',
            },
        },
        buttons: {
            selectmultiple: {
                id: 'products.buttons.selectmultiple',
                defaultMessage: 'Select products',
            },
            share: {
                id: 'products.buttons.share',
                defaultMessage: 'Share',
            },
            cancel: {
                id: 'products.buttons.cancel',
                defaultMessage: 'Cancel',
            },
            addnew: {
                id: 'products.buttons.addnew',
                defaultMessage: 'Add new',
            },
            delete: {
                id: 'products.buttons.delete',
                defaultMessage: 'Delete',
            },
            save: {
                id: 'products.buttons.save',
                defaultMessage: 'Save',
            },
        },
        filter: {
            clients: {
                id: 'products.filter.clients',
                defaultMessage: 'All clients',
            },
            brands: {
                id: 'products.filter.brands',
                defaultMessage: 'All brands',
            },
            editions: {
                id: 'products.filter.editions',
                defaultMessage: 'All editions',
            },
            producttypes: {
                id: 'products.filter.producttypes',
                defaultMessage: 'All product types',
            },
            sizes: {
                id: 'products.filter.sizes',
                defaultMessage: 'All sizes',
            },
        },

    },
});
export default definedMessages;
