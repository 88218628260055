import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faComment, faEye, faPencil} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/nextjs";


const StyledSharePerson = styled.div`

    width: 100%;
    display: block;
    overflow: visible;
    margin-top: 16px;
    height: 50px;
          //@media screen and (max-height: 800px) {
          //  margin-top: 16px;
          //  }
            
    >div{
      display:inline-block;
      float:left;
    }
    .personIcon{
      width:13%;
    }
    .personName{
      width:55%;
      text-align:left;

    margin-top: 4px;
      p{
        font-size:16px;
        opacity:50%;
        line-height: 20px;
        margin:0;
      }
    }
    .personActions{
      width:30%;

      button{
      float:right;
        border:none;
        background:transparent;
        transition: all 0.3s ease;
        border-radius:22px;
        padding:5px;
        padding-right:15px;
        padding-left:15px;
        font-size:16px;
        color:rgba(0,0,0,0.6);
        &:hover,
        &.active{
          color:black;
          background:rgba(0,0,0,0.15);
        }
        &.owner{
            .downIcon{
            color:transparent;
            }
        }
            &.owner:hover,
      &.owner.active{
      cursor:default;
            color:rgba(0,0,0,0.6);
      background:none !important;
      }
      
        svg{
          margin-left:3px;
        }
      }

      .personPopup{
        display:none;
        width: 200px;
      height: 197px;
        background: white;
        border-radius: 22px;
        box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.15);
        z-index: 9;
        //display: block;
        margin-top:40px;
        position: absolute;

        padding:4px;
        &.active{
          display:block;
        }
        hr{
          margin: 0;
          margin-top:4px;
          margin-bottom:4px;
        }
        ul{
          list-style-type:none;
          text-align:left;
          padding:0;
          margin:0;

          li{
            transition:all 0.3s ease;
            font-size:18px;
            padding:5px;
            border-radius:22px;
            padding-left:15px;
            line-height:26px;
            
           
            

            span{
              transition:all 0.3s ease;
              margin-left:10px;
              color:rgba(0,0,0,0.6);
            }
            svg{
              transition:all 0.3s ease;
              color:rgba(0,0,0,0.6);
            }

            &:hover{
              background:rgba(0,0,0,0.15);
              span,
              svg{
                color:rgba(0,0,0,1);
              }
            }
            &.active{
              color:#3d55d7;
              span,
              svg{
                 color:#3d55d7;
                
              }
            }
          }
        }
      }
    }

  
`;


export default function SharePerson ({  props: any, sharePersonIcon,    invite, usrteam, userUUID, sharePersonName, sharePersonEmail, sharePersonRole,shareVersionUUID,shareProductUUID }) {
    const [sharePersonPopupOpened, setSharePersonPopupOpened] = useState(false);


    const [userPermission, setUserPermissions] = useState("none");

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
           //     alert("Outside Clicked.");
                setSharePersonPopupOpened(false);
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref]);

    useEffect(() => {
        if(userPermission !== sharePersonRole){
            setUserPermissions(sharePersonRole);
        }

    }, [sharePersonRole]);

    useEffect(() => {
      if(userPermission === "none"){
          setUserPermissions(sharePersonRole);
      }
    }, [userPermission]);





    const changeUserRole = (newRole) => {

        var shareUUID;

        if(shareProductUUID !== null){
            shareUUID = shareProductUUID;
        }
        if(shareVersionUUID !== null){
            shareUUID = shareVersionUUID;
        }


if(!invite){
    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"share/"+shareUUID+"/members/"+userUUID, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ usrteam, // notice the Bearer before your token
        },
        body: JSON.stringify(    {
            "permissions" : newRole
        }),
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("share/"+shareUUID+"/members/"+userUUID+": "+ data.message + " : " + getCookie("userMail"));
            }

            if(data.message === undefined){
                setUserPermissions(newRole);
            }
        });
} else {
    setUserPermissions(newRole);
}



    };

    return (
        <>
            <StyledSharePerson>


                    <div className={"personIcon"}>
                        <img data-closemodalautocomplete={true}style={{maxWidth:"100%"}} src={sharePersonIcon}/>
                    </div>
                    <div  className={"personName"}>
                        <p data-closemodalautocomplete={true}><b>{sharePersonName}</b></p>
                        <p data-closemodalautocomplete={true}>{sharePersonEmail}</p>
                    </div>
                    <div data-closemodalautocomplete={true}  className={"personActions"}>

                        {userPermission !== "owner"?
                            <button data-closemodalautocomplete={true} onClick={() => setSharePersonPopupOpened(sharePersonPopupOpened ? false : true)}>
                                {userPermission === "owner" ? "Owner" : null}{userPermission === "view" ? "Viewer" : null}{userPermission === "comment" ? "Commenter" : null}{userPermission === "edit" ? "Editor" : null}&nbsp;
                                    <FontAwesomeIcon  icon={faCaretDown} className={"downIcon"}/>
                            </button>
                        :  <button data-closemodalautocomplete={true} className={"owner"}>
                                {userPermission === "owner" ? "Owner" : null}{userPermission === "view" ? "Viewer" : null}{userPermission === "comment" ? "Commenter" : null}{userPermission === "edit" ? "Editor" : null}&nbsp;
                                   <FontAwesomeIcon   icon={faCaretDown} className={"downIcon"}/>
                              </button>}


                        <div className={`${sharePersonPopupOpened ? 'active personPopup' : "personPopup"} `} ref={ref}>
                            <ul>

                                <li onClick={() => changeUserRole("view")} className={`${userPermission === "view" ? 'active' : null} `}> <FontAwesomeIcon  icon={faEye} className={"downIcon"}/><span>Viewer</span></li>
                                <li  onClick={() => changeUserRole("comment")}  className={`${userPermission === "comment" ? 'active' : null} `}> <FontAwesomeIcon  icon={faComment} className={"downIcon"}/><span>Commenter</span></li>
                                <li  onClick={() => changeUserRole("edit")}  className={`${userPermission === "edit" ? 'active' : null} `}> <FontAwesomeIcon  icon={faPencil} className={"downIcon"}/><span>Editor</span></li>
                                <hr/>
                                <li><span>Add ownership</span></li>
                                <li><span>Remove access</span></li>
                            </ul>
                        </div>
                    </div>


            </StyledSharePerson>

        </>
    )
}