import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";

import * as Sentry from "@sentry/nextjs";

export default function DialogDeleteGroups ({productDeleteUUID,editAllUUID,changeEditAllUUID,loadProductsFiltersAPI,
                                      toggleDelete}) {

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteTransitionShow, setDeleteTransitionShow] = useState(false);

    const changeDeleteShow = (variable) => {

        if(variable === true){

            setDeleteShow(variable);
            setTimeout(function() {
                setDeleteTransitionShow(true);
            },1);




        } else{


            setDeleteTransitionShow(false);
            setTimeout(function() {
                setDeleteShow(variable);
            },500);



        }

    };
    const deleteProductCheck = (productID) => {
        console.log("deltePRoduct check ",productID,productDeleteUUID);
        // setTrigger(productID);
       // changeProductDeleteUUID(productID);

        changeDeleteShow(true);
    };

    const [userTeamLoading, setUserTeamLoading] = useState(true);

    const [deleteState, setDeleteState] = useState(1);

    const [deleteParent, setDeleteParent] = useState("");
    const closeDelete  = () => {
        changeEditAllUUID(null);
        closeDeleteShow();
    }
    const showDelete  = (uuid) => {
        changeEditAllUUID(uuid);
    }

    const deleteGroup = () => {



        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups/"+editAllUUID, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                //  console.log("clientadd complete", data);
                if(data.message !== undefined){
                    Sentry.captureException("groups"+editAllUUID+": "+ data.message + " : " + getCookie("userMail"));
                }
                if(data.message === undefined){
                   closeDelete();

                    loadProductsFiltersAPI();

                }

            });

    }

    let closeDeleteShow = () => {
console.log("CLOSING SHOW");
        changeDeleteShow(false);

        setTimeout(function() {
            setLoadingDelete(false);
        },300);
    };
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const toggleDeleteInside = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{

            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDelete && toggleDelete(toggleDeleteInside);
    }, [toggleDelete, toggleDeleteInside]);



    const closeModalOutsideClick = (e) => {
        console.log(e.target, e.target.getAttribute("data-closemodal"));

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDeleteShow(false);
        }
    }


    return (
        <>

            <ModalsWrapper className={`deleteCheck  ${deleteTransitionShow ? 'showTransition' : 'hideTransition'} ${!deleteShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           style={{zIndex:"999999999999999999999"}}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'deleteProductDialog'} style={{ minHeight: 'unset',     height: "170px" }}>
                    <div className={'modal-header'}>
                        <h4>Delete</h4>

                    </div>


                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span>Are you sure you wanna delete ?</span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => closeDelete()}>
                                    Cancel
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={deleteGroup}>
                                    Delete
                                </GlobalButtonFinal>

                            </div>


                        </div>





                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}