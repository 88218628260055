import styled from 'styled-components';

export const StyledProductFilter = styled.div`
    width: 100%;
    position: relative;
    margin: auto auto;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -5px;
    min-height: 47px;
    button,
    .filterButton {
        color: #4c4c4c !important;
        position: relative;
        background: transparent;
        //border: none;
        font-size: 16px;
        font-weight: 500;
        z-index: 700;
        transition: all 0.3s ease;
        margin-top: 5px;
        border-radius: 30px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        // margin-left:5px;
        //opacity:0.8;
        border: 2px solid lightgrey;
        width: fit-content;

        &.activated {
            padding: 3px;
            padding-left: 10px;
        }
        &:not(:first-child) {
            margin-left: 10px;
        }

        svg {
            color: #4c4c4c !important;
            margin-right: 5px;
        }
        //svg.span{
        //  opacity:0.7;
        //}

        @media (max-width: 1500px) {
            //margin-left: 7px;
            font-size: 15px;
        }
        &:hover,
        &.active {
            background: lightgray;
            //transform:scale(1.1);
        }

        //@media(max-width:991px){
        //  width: 30%;
        //  //margin-left: 0 !important;
        //}
    }
    .filterButton {
        display: inline-block;
        // height:38px;

        > div {
            display: inline-block;

            width: fit-content;
        }

        .filterTag {
            border: none;
            background: #d9d9d9;
            margin-left: 5px;
            border-radius: 30px;
            transition: all 0.3s ease;
            padding: 2px;
            padding-left: 15px;
            padding-right: 5px;
            display: inline-block;
            svg {
                margin-left: 5px;
                height: 11px;
            }
            &:hover {
                background: #f8f8f8;
            }

            .close {
                padding: 0px;
                border-radius: 19px;
                padding-right: 8px;
                padding-left: -1px;
                margin-left: 5px;
                transition: all 0.3s ease;
                padding-bottom: 2px;
                position: relative;
                top: -1px;
                &:hover {
                    background: lightgray;
                    color: white;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
    .filterButtons {
    }
    .gridSwitcher {
        @media (max-width: 1500px) {
            font-size: 15px;
        }
        @media (max-width: 1400px) {
            display: none;
        }
    }

    //button:after
    //{
    //  content: "";
    //  background: lightgray;
    //  position: absolute;
    //  bottom: 0;
    //  right: -14px;
    //  height: 100%;
    //  width: 1px;
    //  @media(max-width:991px){
    //    display:none
    //  }
    //  // @media(max-width:1500px){
    //  //
    //  //     right: -4px;
    //  //
    //  // }
    //}
    //
    //
    //button:last-child:after
    //{
    //  display:none;
    //}
    .filterButtons {
        @media (max-width: 1500px) {
            text-align: left;
        }
    }

   
`;



export const StyledPagination = styled.div`


    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: all 0.3s ease;
    z-index:1;
  text-align: center;
  position:relative;
    span,
    button{
      opacity:50%;
    }
    span{
      font-size:16px;
      margin-left:15px;
      margin-right:15px;
    }
    button{
      border:none;
      outline:none;
      transition: all 0.3s ease;
      border-radius:30px;
    padding-left: 11px;
    background:transparent;
    padding-right: 11px;
    padding-top: 4px;
    padding-bottom: 3px;
    }
    button:hover{
        background:#cacaca;
      opacity:100%;
    }
  }
`;

export const StyledProductsContent = styled.div`
    &.chooseInsideShrink {
        margin-top: -50px;
    }
`;
