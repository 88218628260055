import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {useRouter} from "next/router";

export default function DialogAlert ({alertType, message,toggleAlert, showAndLoadLayers, changeTabWithNewLoad, doRedirect,typeWindow,finishUpdateTexturesOnProduct}) {

    const [alertShow, setAlertShow] = useState(false);
    const [alertTransitionShow, setAlertTransitionShow] = useState(false);
    const router = useRouter();
    const changeAlertShow = (variable) => {

        if(variable === true){

            setAlertShow(variable);
            setTimeout(function() {
                setAlertTransitionShow(true);
            },1);




        } else{


            setAlertTransitionShow(false);
            setTimeout(function() {
                setAlertShow(variable);
            },500);



        }

    };

    const toggleAlertInside = useCallback((): void => {
        if(alertShow){
            changeAlertShow(false);
        }else{
            changeAlertShow(true);
        }


    }, []);
    useEffect(() => {

        toggleAlert && toggleAlert(toggleAlertInside);
    }, [toggleAlert, toggleAlertInside]);


    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeAlertShow(false);
        }
    }
    const goToHomepage = () => {

     router.push("/");
    }


    const closeAndUpdateLayers = () => {
        changeAlertShow(false);
        finishUpdateTexturesOnProduct();
    }
    const closeAndShowLayers = () => {
        changeAlertShow(false);
        showAndLoadLayers();
    }

    const closeAndLoadTab = () => {
        changeAlertShow(false);
        changeTabWithNewLoad();
    }

    return (
        <>
            <ModalsWrapper className={`alertCheck  ${alertTransitionShow ? 'showTransition' : 'hideTransition'}
             ${!alertShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center alert"}
                    id={'alertProductDialog'}>
                    <div className={'modal-header'}>

                        {alertType === "information" ?

                            <h4>Information message</h4>
                        : null}
                        {alertType === "warning" ?

                            <h4 className={"warning"}>Warning message</h4>
                            : null}
                        {alertType === "global-error.tsx" ?

                            <h4 className={"global-error.tsx"}>Error message</h4>
                            : null}


                    </div>




                    <div className={'modal-content shareProduct'} style={{display:"block"}}>
                        <span style={typeWindow  ? {width:"100%",    fontSize: "15px", color: "var(--black-60, #666)"} : {}}>{message}</span>


                        {typeWindow === "layers" ?
                            <div className={"modal-buttons"}  style={{    width: "100%", height:"auto"}}>


                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={()=> closeAndUpdateLayers()}>
                                      Yes
                                </GlobalButtonFinal>

                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={ () => closeAndShowLayers()}>
                                    No, I will Change Layers
                                </GlobalButtonFinal>

                            </div>
                            : null}

                        {typeWindow === "switchTabs" ?
                            <div className={"modal-buttons space"}  style={{    width: "100%", height:"auto"}}>

                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={ () => changeAlertShow(false)}>
                                    No
                                </GlobalButtonFinal>

                                <GlobalButtonFinal style={{ width: "fit-content" , marginLeft:"10px!important"}} className={"borderBlue btn-circle"}
                                                   onClick={()=> closeAndLoadTab()}>
                                    Yes
                                </GlobalButtonFinal>


                            </div>
                            : null}

                        {typeWindow === "load" ?
                            <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                               onClick={doRedirect ? () => goToHomepage() : () => changeAlertShow(false)}>
                                OK
                            </GlobalButtonFinal>
                            : null}

                        {!typeWindow ?
                            <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                               onClick={doRedirect ? () => goToHomepage() : () => changeAlertShow(false)}>
                                OK
                            </GlobalButtonFinal>
                            : null}


                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}