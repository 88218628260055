import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faComment, faEye, faPencil} from "@fortawesome/free-solid-svg-icons";



const StyledShareTeam = styled.div`

    width: 100%;
    display: block;

    margin-top: 20px;
  
    border-radius: 32px;
    padding-top: 10px;
    padding-bottom: 10px;

  overflow:hidden;
  transition:all 0.3s ease;
  height: 68px;
 
  .openingTeam{
    position: absolute;
    left: 0;
    top: 47px;
    width: 65%;
    overflow: auto;
    z-index:9;
    height: 68px;
  }
  &.open{
    overflow: auto;
    height:fit-content;
    background: lightgray;
  }
  &:hover{
    background: lightgray;
  }
    
          @media screen and (max-height: 800px) {
            margin-top: 10px;
            }
            
    >div{
      display:inline-block;
      float:left;
    }
    .teamIcon{
      width:13%;
    }
    .teamContent{
    width:100%;
    }
    .teamName{
      width:45%;
      text-align:left;

    margin-top: 4px;
      p{
        font-size:16px;
        opacity:50%;
        line-height: 20px;
        margin:0;
      }
    }
    .teamActions{
      width:40%;

      button{
      float:right;
        border:none;
        background:transparent;
        transition: all 0.3s ease;
        border-radius:22px;
        padding:5px;
        padding-right:15px;
        padding-left:15px;
        font-size:16px;
        color:rgba(0,0,0,0.6);
        &:hover,
        &.active{
          color:black;
          background:rgba(0,0,0,0.15);
        }
        &.owner{
            .downIcon{
            color:transparent;
            }
        }
            &.owner:hover,
      &.owner.active{
      cursor:default;
            color:rgba(0,0,0,0.6);
      background:none !important;
      }
      
        svg{
          margin-left:3px;
        }
      }

      .teamPopup{
        display:none;
        width: 200px;
      height: 197px;
        background: white;
        border-radius: 22px;
        box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.15);
        z-index: 9;
        //display: block;
        margin-top:40px;
        position: absolute;

        padding:4px;
        &.active{
          display:block;
        }
        hr{
          margin: 0;
          margin-top:4px;
          margin-bottom:4px;
        }
        ul{
          list-style-type:none;
          text-align:left;
          padding:0;
          margin:0;

          li{
            transition:all 0.3s ease;
            font-size:18px;
            padding:5px;
            border-radius:22px;
            padding-left:15px;
            line-height:26px;
            
           
            

            span{
              transition:all 0.3s ease;
              margin-left:10px;
              color:rgba(0,0,0,0.6);
            }
            svg{
              transition:all 0.3s ease;
              color:rgba(0,0,0,0.6);
            }

            &:hover{
              background:rgba(0,0,0,0.15);
              span,
              svg{
                color:rgba(0,0,0,1);
              }
            }
            &.active{
              color:#3d55d7;
              span,
              svg{
                 color:#3d55d7;
                
              }
            }
          }
        }
      }
    }

  
`;


export default function ShareTeam ({  props: any, typeShare, changeTeamRole,shareTeamIcon, shareTeamName, shareTeamEmail, shareTeamRole,children}) {
    const [shareTeamPopupOpened, setShareTeamPopupOpened] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
           //     alert("Outside Clicked.");
                setShareTeamPopupOpened(false);
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref]);

    const [shareTeamOpen, setShareTeamOpen] = useState(false);

    return (
        <>
            <StyledShareTeam className={`${shareTeamOpen ? 'open' : ""} `} >


                <div className={"openingTeam"} onClick={()=> setShareTeamOpen(shareTeamOpen ? false : true)}>

                </div>

                    <div className={"teamIcon"}>
                        <img data-closemodalautocomplete={true} style={{maxWidth:"100%"}} src={shareTeamIcon}/>
                    </div>
                    <div  className={"teamName"}>
                        <p data-closemodalautocomplete={true}><b>{shareTeamName}</b></p>

                    </div>
                    <div  className={"teamActions"}>

                        {shareTeamRole === "none" ?
                            <button data-closemodalautocomplete={true}onClick={() => setShareTeamPopupOpened(shareTeamPopupOpened ? false : true)} style={{marginTop:"6px"}}>
                                Default (Viewer)&nbsp;
                                <FontAwesomeIcon icon={faCaretDown} className={"downIcon"}/>
                            </button>
                                :
                            <button data-closemodalautocomplete={true}onClick={() => setShareTeamPopupOpened(shareTeamPopupOpened ? false : true)}  style={{marginTop:"6px"}}>
                                {shareTeamRole === "owner" ? "Default (Owner)" : null}{
                                    shareTeamRole === "view" ? "Default (Viewer)" : null}

                                {shareTeamRole === "comment" ? "Default (Commenter)" : null}

                                {shareTeamRole === "edit" ? "Default (Editor)" : null}&nbsp;
                                <FontAwesomeIcon icon={faCaretDown} className={"downIcon"}/>
                            </button>
                        }
                        <div className={`${shareTeamPopupOpened ? 'active teamPopup' : "teamPopup"} `} ref={ref}>
                            <ul>

                                <li data-closemodalautocomplete={true} onClick={()=>changeTeamRole("view", typeShare)} className={`${shareTeamRole === "none" || shareTeamRole === "view" ? 'active' : null} `}> <FontAwesomeIcon  icon={faEye} className={"downIcon"}/><span>Viewer</span></li>
                                <li data-closemodalautocomplete={true}onClick={()=>changeTeamRole("comment", typeShare)}  className={`${shareTeamRole === "comment" ? 'active' : null} `}> <FontAwesomeIcon  icon={faComment} className={"downIcon"}/><span>Commenter</span></li>
                                <li data-closemodalautocomplete={true} onClick={()=>changeTeamRole("edit", typeShare)}  className={`${shareTeamRole === "edit" ? 'active' : null} `}> <FontAwesomeIcon  icon={faPencil} className={"downIcon"}/><span>Editor</span></li>
                                <hr/>
                                <li data-closemodalautocomplete={true}><span>Add ownership</span></li>
                                <li data-closemodalautocomplete={true}><span>Remove access</span></li>
                            </ul>
                        </div>
                    </div>

                <div className={"teamContent"} data-closemodalautocomplete={true}>
                    {children}
                </div>


            </StyledShareTeam>

        </>
    )
}