import React, { useEffect, useState } from 'react';
import {StyledProductFilter} from "./styles/ProductsStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMugHot, faPen, faPlus, faTrash, faX
} from "@fortawesome/pro-regular-svg-icons";
import messages from "../../../../src/messages";
import {
    GlobalButtonFinal,
    ModalsWrapper,
    ModalWindowRightSide
} from "../../global/GlobalStyles";
import {getCookie} from "cookies-next";


import * as Sentry from "@sentry/nextjs";
import DialogGroupsModalEditAdd from '../../global/dialogs/DialogGroupsModalEditAdd';
import { useRouter } from 'next/router';
import DialogDeleteGroups from '../../global/dialogs/DialogDeleteGroups';
import DialogAlert from '../../global/dialogs/DialogAlert';

export default function GroupsFilter({ props: any,type,
                                         loadProducts,
                                         filterActive,
                                         loadinProductsComplete,
                                         setFilterActive,
                                         setFilterClientsProducts,
                                         setFilterBrandsProducts,
                                         setFilterEditionsProducts}) {

    const [allFilterDataAPI, setAllFilterDataAPI] = useState({});

    const [originalDataAPI, setOriginalDataAPI] = useState({});


    const [filterClients, setFilterClients] = useState([]);
    const [filterBrands, setFilterBrands] = useState([]);
    const [filterEditions, setFilterEditions] = useState([]);
    const [filterProductTypes, setFilterProductTypes] = useState([]);
    const [filterSizes, setFilterSizes] = useState([]);

    const [filterClientsOpened, changeClientFilterOpen] = useState(false);
    const [filterBrandsOpened, changeBrandsFilterOpen] = useState(false);
    const [filterEditionsOpened, changeEditionsFilterOpen] = useState(false);
    const [filterProductTypesOpened, changeProductTypesFilterOpen] = useState(false);
    const [filterSizesOpened, changeSizesFilterOpen] = useState(false);



    const [activeFilterClients, setActiveFilterClients] = useState([]);

    const [activeFilterBrands, setActiveFilterBrands] = useState([]);

    const [activeFilterEditions, setActiveFilterEditions] = useState([]);
    const [activeFilterProductTypes, setActiveFilterProductTypes] = useState([]);
    const [activeFilterSizes, setActiveFilterSizes] = useState([]);


    const router = useRouter();





    const [clientModalActive, changeClientModal] = useState(false);
    const [brandModalActive, changeBrandModal] = useState(false);
    const [editionsModalActive, changeEditionsModal] = useState(false);
    const [editAllModalActive, changeEditAllModalActive] = useState(false);
    const [editAllTypeHeaderText, changeEditAllTypeHeaderText] = useState(false);
    //
    // modalTransitionShowActiveFilterSide
    // activeFilterSide
    // activeFilterSide

    const [defaultFilteredValues, setDefaultFilteredValues] = useState({});


    const [activeFilterSide, setActiveFilterSide] = useState(false);
    const [modalTransitionShowActiveFilterSide, setModalTransitionShowActiveFilterSide] = useState(false);
    const changeActiveFilterSide = (variable) => {
//console.log("CHANGE FILTER SIDE");
        if(variable === true){

            setActiveFilterSide(variable);
            setTimeout(function() {
                setModalTransitionShowActiveFilterSide(true);
            },1);

        } else{

            setModalTransitionShowActiveFilterSide(false);
            setTimeout(function() {
                changeClientFilterOpen(false);
                changeBrandsFilterOpen(false);
                changeEditionsFilterOpen(false);
                changeProductTypesFilterOpen(false);
                changeSizesFilterOpen(false);
                setActiveFilterSide(variable);
            },500);
        }
    };


    const [modalTransitionShow, setModalTransitionShow] = useState(false);


    const showDelete  = (uuid) => {
        handleClickDelete();
        changeEditAllUUID(uuid);
        changeEditAllDeletecheck(true);
      //  setModalTransitionShow(true);
    }

    const [editAllDeletecheck, changeEditAllDeletecheck] = useState(false);
    const [editAllName, changeEditAllName] = useState("");
    const [editAllType, changeEditAllType] = useState("");
    const [editAllIcon, changeEditAllIcon] = useState("");
    const [editAllUUID, changeEditAllUUID] = useState("");

    const [clientPlaceholderText, setClientPlaceholderText] = useState(messages.products.modal.clientname.defaultMessage);
    const [brandPlaceholderText, setBrandPlaceholderText] = useState(messages.products.modal.brandname.defaultMessage);
    const [editionPlaceholderText, setEditionPlaceholderText] = useState(messages.products.modal.editionname.defaultMessage);

    const startEditAnything = (type, uuid, editName, logo) => {
        changeEditAllName(editName);
        changeEditAllIcon(logo);
        changeEditAllUUID(uuid);
        changeEditAllType(type);

        if(type === "client"){
            changeEditAllTypeHeaderText(clientPlaceholderText);
        }
        if(type === "brand"){
            changeEditAllTypeHeaderText(brandPlaceholderText);
        }
        if(type === "edition"){
            changeEditAllTypeHeaderText(editionPlaceholderText);
        }


        setModalShow(true, "editAll");
    }

    const setModalShow = (variable, type) => {

        if(variable === true){
            // setLoadProductsShowVariable(variable);

            if(type === "client"){
                changeClientModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "brand"){
                changeBrandModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "edition"){
                changeEditionsModal(true);
                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
            if(type === "editAll"){
                changeEditAllModalActive(true);


                setTimeout(function() {
                    setModalTransitionShow(true);
                }, 1);
            }
        } else{

            setModalTransitionShow(false);
            setTimeout(function() {

                changeClientModal(false);
                changeBrandModal(false);
                changeEditionsModal(false);
                changeEditAllModalActive(false);

                //setLoadProductsShowVariable(variable);
            },300);

        }

    };





    const [openedFilterName, setOpenedFilterName] = useState('Clients');

    const openFilter = filter => {
      //  console.log("OPENIIGN FILTER", allFilterDataAPI, originalDataAPI, selectedValues);

//setOriginalDataAPI({ ...allFilterDataAPI });
        setOriginalDataAPI(JSON.parse(JSON.stringify(allFilterDataAPI)));
        setOriginalSelectedValues(JSON.parse(JSON.stringify(selectedValues)));
       // console.log("SETTING ORIGINAL VALUES2 ", selectedValues);
        changeClientFilterOpen(false);
        changeBrandsFilterOpen(false);
        changeEditionsFilterOpen(false);
        changeProductTypesFilterOpen(false);
        changeSizesFilterOpen(false);

        changeActiveFilterSide(true);

        if(filter === "clients" ){
            setOpenedFilterName("Clients");

            changeClientFilterOpen(true);

        }
        if(filter === "brands" ){
            changeBrandsFilterOpen(true);
            setOpenedFilterName("Brands");

        }
        if(filter === "editions" ){
            changeEditionsFilterOpen(true);
            setOpenedFilterName("Editions");


        }
        if(filter === "productTypes" ){
            changeProductTypesFilterOpen(true);
            setOpenedFilterName("Product types");

        }
        if(filter === "sizes" ){
            changeSizesFilterOpen(true);
            setOpenedFilterName("Sizes");

        }

    };


    const removeFilterGroup = (uuid, typeGroup) => {

        var selectedValuesArr = {...selectedValues};

        var type= typeGroup;
        var dataAPI = { ...allFilterDataAPI };
        var countCheckboxes = 0;

        Object.keys(dataAPI).forEach(filterKey => {
            if(filterKey=== "values"){
                Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                    Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                     //   console.log("FILTRUJU NECO ", filterKeyValues, dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid, uuid);

                            if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === uuid) {
                                if(dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked){
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                } else {
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                }
                            }

                        if(dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked){
                            countCheckboxes++;
                        }
                    });
                });
            }
        });

        if(Object.keys(selectedValuesArr).length === 0){
            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);
            setSelectedValues({});
            simplifyAllValues();
            return;
        }

        if(Object.values(selectedValuesArr).indexOf(uuid) > -1){
            delete selectedValuesArr[typeGroup];
        }



        showFilteredProducts(selectedValuesArr);
        setSelectedValues({
            ...selectedValuesArr
        });



    };



    const showFilteredProducts = (selectedValuesArr) => {
var newValues = selectedValues;

if(selectedValuesArr){
    newValues= selectedValuesArr;
}

        loadProducts(null, newValues);




        let filtersVariableStringClients = "&clients=";
        let filtersVariableStringBrands = "&brands=";
        let filtersVariableStringEditions = "&editions=";
        let filtersVariableStringProductTypes = "&product_types=";
        let filtersVariableStringPackages = "&packages=";
        let filtersVariableStringVolumes = "&volumes=";

        if(newValues.clients){

            filtersVariableStringClients += newValues.clients.toString();
        } else {
            filtersVariableStringClients ="";
        }
        if(newValues.brands){

            filtersVariableStringBrands += newValues.brands.toString();
        }else {
            filtersVariableStringBrands="";
        }
        if(newValues.editions){

            filtersVariableStringEditions += newValues.editions.toString();
        }else {
            filtersVariableStringEditions="";
        }
        if(newValues.product_types){

            filtersVariableStringProductTypes += newValues.product_types.toString();
        }else {
            filtersVariableStringProductTypes="";
        }
        if(newValues.packages){

            filtersVariableStringPackages += newValues.packages.toString();
        }else {
            filtersVariableStringPackages="";
        }
        if(newValues.volumes){

            filtersVariableStringVolumes += newValues.volumes.toString();
        }else{
            filtersVariableStringVolumes="";
        }


        var fullVariableString =filtersVariableStringClients+
            filtersVariableStringBrands+
            filtersVariableStringEditions+
            filtersVariableStringProductTypes+
            filtersVariableStringPackages+
            filtersVariableStringVolumes;

       // console.log(fullVariableString, "FULL NEW URL STRING");

        if(fullVariableString.length === 0){
            window.history.replaceState(null, "Products", "/")
        }else {
            window.history.replaceState(null, "Products", "/?"+fullVariableString)
        }



        changeActiveFilterSide(false);
        setOriginalDataAPI(JSON.parse(JSON.stringify(allFilterDataAPI)));
        setOriginalSelectedValues(JSON.parse(JSON.stringify(newValues)));

     //   console.log("SETTING ORIGINAL VALUES ", newValues);
        if(filteredValues.clients.length > 0 ||
            filteredValues.brands.length > 0 ||
            filteredValues.editions.length > 0 ||
            filteredValues.product_types.length > 0 ||
            filteredValues.product_types.volumes.length > 0
        ){
            setFilterActive(true);
        }else {
            setFilterActive(false);
        }

    };




    const onChangeAddFilter = (type, value) => {

// clients uuid



        var dataAPI = { ...allFilterDataAPI };
        var countCheckboxes = 0;

        Object.keys(dataAPI).forEach(filterKey => {

            if(filterKey=== "values"){
                Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                        Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                            if(filterKeyValues === type){
                            if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === value) {

                                if(dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked){
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                } else {
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                }

                            }
                            }

                             //   console.log(dataAPI[filterKey][filterKeyValues][filterKeyValuesInside]);
                                if(dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked){
                                    countCheckboxes++;
                                }

                        });





                });

            }
        });


        if (null === value) {
            setSelectedValues({});

            Object.keys(dataAPI).forEach(filterKey => {

                if(filterKey=== "values") {
                    Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {


                        Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {

                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
    

                        });

                    });
                }
            });

            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);

            simplifyAllValues();
            return;
        }


        if (countCheckboxes === 0) {
            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);
            setSelectedValues({});
            simplifyAllValues();
            return;
        }
     ///   console.log("SETTING INSIDE VALUES", selectedValues);
        setSelectedValues({
            ...selectedValues,
            [type]: value
        });

    }




    const [filteredValues, setFilteredValues] = useState({});
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {


        if (0 === Object.keys(selectedValues).length) {
           // console.log("SETUJU DEFAULTNI VALUES", defaultFilteredValues);
            setFilteredValues(defaultFilteredValues);
            return;
        }


        const _filteredValues = {
            clients : [],
            brands : [],
            editions : [],
            product_types : [],
            volumes : [],
        };

        var newData = JSON.parse(JSON.stringify(allFilterDataAPI));

        newData.relations.forEach((rel) => {


            Object.keys({ ...defaultFilteredValues }).forEach(filterKey => {
                const relKey = filterKeyToRelMappings[filterKey];

              //  console.log("RELATIONS",relKey, filterKey,defaultFilteredValues[filterKey] );
                if (rel[relKey] === undefined || rel[relKey] === null) { return; }
                let candidate = true;

                Object.keys({ ...selectedValues }).forEach((selectedValueKey) => {

               //     console.log("PROJIZDIM RELAITONS ", selectedValueKey, filterKey, rel,rel[relKey],relKey );
                    if (selectedValueKey === filterKey) { return; }
                    if (check(selectedValues[selectedValueKey], `${rel[filterKeyToRelMappings[selectedValueKey]]}`)) { return; }

                    candidate = false;
                });
//console.log("CANDIADATE ",candidate,_filteredValues[filterKey].indexOf(`${rel[relKey]}`),rel[relKey] );
                if (candidate && -1 === _filteredValues[filterKey].indexOf(`${rel[relKey]}`)) {

                    _filteredValues[filterKey].push(`${rel[relKey]}`);
                }
            });
        });

        setFilteredValues(JSON.parse(JSON.stringify(_filteredValues)));

    }, [selectedValues]);



    const filterKeyToRelMappings = {
        clients : 'client',
        brands : 'brand',
        editions : 'edition',
        product_types : 'product_type',
        volumes : 'volume',
    };




    const check = (_selectedValues, _rel) => {
        const isMultiple = Array.isArray(_selectedValues);

        return isMultiple ? -1 !== _selectedValues.indexOf(_rel) : _selectedValues === _rel;
    }


    const [filterRelations, setFilterRelations] = useState([]);

    const [originalSelectedValues, setOriginalSelectedValues] = useState({});

    const loadProductsFiltersAPI = () => {


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"filter/products", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("edit filter/products"+": "+ data.message + " : " + getCookie("userMail"));
                }


      //          console.log("LOADING PROUDCT FILTER API ",data);

                setFilterClientsProducts(data.values.clients);
                setFilterBrandsProducts(data.values.brands);
                setFilterEditionsProducts(data.values.editions);

                setFilterClients(data.values.clients);
                setFilterBrands(data.values.brands);
                setFilterEditions(data.values.editions);
                setFilterProductTypes(data.values.product_types);
                setFilterSizes(data.values.volumes);
                setFilterRelations(data.relations);

const dataAPI = data;

             Object.keys(dataAPI).forEach(filterKey => {

                        if(filterKey=== "values"){
                            Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {


                              //  console.log(dataAPI[filterKey][filterKeyValues]);


                                Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {

                                    if (typeof dataAPI[filterKey][filterKeyValues][filterKeyValuesInside] === 'string') {
                                        // 👇️ this runs
                                        dataAPI[filterKey][filterKeyValues][filterKeyValuesInside] = {uuid: dataAPI[filterKey][filterKeyValues][filterKeyValuesInside], name:dataAPI[filterKey][filterKeyValues][filterKeyValuesInside] , checked:false};
                                    } else {
                                        dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                    }


                                });


                            });

                        }
                });

                setAllFilterDataAPI({ ...dataAPI });

            });
    };


    const [isSimplified, setIsSimplified] = useState(false);

    const [isInitedOnLoad, setIsInitedOnLoad] = useState(false);

    useEffect(() => {
        if(allFilterDataAPI.values){
            simplifyAllValues();

            if(isSimplified && loadinProductsComplete && !isInitedOnLoad){
                if(router.query.clients ){
                 //   console.log("SER DEFAUTLF", router.query.clients.split(","));
                    onChangeAddFilter("clients", router.query.clients.split(",")[0]);
                    showFilteredProducts({clients:router.query.clients.split(",")[0]});
                }
                if(router.query.brands ){
                  //  console.log("SER DEFAUTLF", router.query.brands.split(","));
                    onChangeAddFilter("brands", router.query.brands.split(",")[0]);
                    showFilteredProducts({brands:router.query.brands.split(",")[0]});
                }
                if(router.query.editions ){
                  //  console.log("SER DEFAUTLF", router.query.editions.split(","));
                    onChangeAddFilter("editions", router.query.editions.split(",")[0]);
                    showFilteredProducts({editions:router.query.editions.split(",")[0]});
                }

                setIsInitedOnLoad(true);
            }
        }
    }, [allFilterDataAPI,isSimplified,loadinProductsComplete]);

    const simplifyAllValues = () => {
    if(!filterActive){
        const values = {};
        Object.keys(allFilterDataAPI.values).forEach(key => {
            values[key] = allFilterDataAPI.values[key].map(val => {
                return typeof val === "object" ? val.uuid : val;
            })
        });


        setFilteredValues(values);
        setDefaultFilteredValues(values);

        setIsSimplified(true);
    }
    };


    useEffect(() => {
         //   console.log("MENI SE TED ORIGINALY  ", originalDataAPI);
        var newClientTags =[];
        var newBrandTags = [];
        var newEditionTags = [];
        var newProductTypes = [];
        var newSizes = [];

        Object.keys(originalDataAPI).forEach(filterKey => {

            if(filterKey=== "values"){
                Object.keys(originalDataAPI[filterKey]).forEach(filterKeyValues => {

                        Object.keys(originalDataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                                    if(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked){


                                        if(filterKeyValues === "clients"){
                                            newClientTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        }
                                        if(filterKeyValues === "brands"){
                                            newBrandTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        }
                                        if(filterKeyValues === "editions"){
                                            newEditionTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        }
                                        if(filterKeyValues === "product_types"){
                                            newProductTypes.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        }
                                        if(filterKeyValues === "volumes"){
                                            newSizes.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        }

                                    }
                        });



                });

            }
        });

        setActiveFilterClients(newClientTags);
        setActiveFilterBrands(newBrandTags);
        setActiveFilterEditions(newEditionTags);
        setActiveFilterProductTypes(newProductTypes);
        setActiveFilterSizes(newSizes)


    }, [originalDataAPI]);

    useEffect(() => {
        loadProductsFiltersAPI();
    }, []);

    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            console.log("CHANGING SIDE");
            changeActiveFilterSide(false);
          setAllFilterDataAPI({ ...originalDataAPI });
            setSelectedValues({ ...originalSelectedValues });

        }
    }

    const closeModalOutsideClickEdit = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            setModalShow(false, "editAll");
        }
    }

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
       // changeLoadingShowTop(false);
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {
        console.log("CLICKA NA warmomg");
    };

    let handleClickDelete = () => {
        console.log("CLICKA NA handleClickDelete");
    };

    return (
        <>

        <StyledProductFilter>


            <div className={'filterButtons'}  data-closemodalmenu={true}>

                <div data-closemodalmenu={true} className={`filterButton ${filterClientsOpened ? 'active' : '' }  ${activeFilterClients.length>0 ?"activated": null} `}>

                    <div className={"filterName"}>
                        <span    id={"clientFilterButton"}  onClick={() => openFilter("clients")}>Clients
                        </span>
                    </div>

                    {activeFilterClients.length>0 ?
                        <div className={"filterTags"} data-closemodalmenu={true}>

                            {allFilterDataAPI.values.clients.map((value, index) => {
                                if(activeFilterClients.includes(value.uuid)){
                                    return    <div className={"filterTag"}>
                                       {value.name}
                                        <span  onClick={() => removeFilterGroup(value.uuid, "clients")} className={"close"}>&nbsp;&nbsp;x</span>
                                    </div>
                                }
                            })}

                        </div>
                    : null}

                </div>

                <div data-closemodalmenu={true} className={`filterButton ${filterBrandsOpened ? 'active' : '' }  ${activeFilterBrands.length>0 ?"activated": null} `}>

                    <div className={"filterName"}>
                        <span      onClick={() => openFilter("brands")}>Brands
                        </span>
                    </div>

                    {activeFilterBrands.length>0 ?
                        <div className={"filterTags"}>

                            {filterBrands.map((value, index) => {
                                if(activeFilterBrands.includes(value.uuid)){
                                    return    <div className={"filterTag"}>
                                        {value.name}
                                        <span  onClick={() => removeFilterGroup(value.uuid,"brands")} className={"close"}>&nbsp;&nbsp;x</span>
                                    </div>
                                }
                            })}

                        </div>
                        : null}
                </div>

                <div data-closemodalmenu={true} className={`filterButton ${filterEditionsOpened ? 'active' : '' }  ${activeFilterEditions.length>0 ?"activated": null} `}
                >

                    <div className={"filterName"}>
                        <span      onClick={() => openFilter("editions")}>Editions
                        </span>
                    </div>

                    {activeFilterEditions.length>0 ?
                        <div className={"filterTags"}>

                            {filterEditions.map((value, index) => {
                                if(activeFilterEditions.includes(value.uuid)){
                                    return    <div className={"filterTag"}>
                                        {value.name}
                                        <span  onClick={() => removeFilterGroup(value.uuid,"editions")} className={"close"}>&nbsp;&nbsp;x</span>
                                    </div>
                                }
                            })}

                        </div>
                        : null}
                </div>



                {type !== "assignProduct" ?
                    <>

                        <div data-closemodalmenu={true} className={`filterButton ${filterProductTypesOpened ? 'active' : '' }  ${activeFilterProductTypes.length>0 ?"activated": null} `}>

                            <div className={"filterName"}>
                                <span      onClick={() => openFilter("productTypes")}>Product Types

                                </span>
                            </div>

                            {activeFilterProductTypes.length>0 ?
                                <div className={"filterTags"}>

                                    {filterProductTypes.map((value, index) => {
                                        if(activeFilterProductTypes.includes(value.uuid)){
                                            return    <div className={"filterTag"}>
                                                {value.name}
                                                <span  onClick={() => removeFilterGroup(value.uuid,"product_types")} className={"close"}>&nbsp;&nbsp;x</span>
                                            </div>
                                        }
                                    })}

                                </div>
                                : null}
                        </div>

                        <div data-closemodalmenu={true} className={`filterButton ${filterSizesOpened ? 'active' : '' }  ${activeFilterSizes.length>0 ?"activated": null} `}>

                            <div className={"filterName"}>
                                <span      onClick={() => openFilter("sizes")}>Sizes

                                </span>
                            </div>

                            {activeFilterSizes.length>0 ?
                                <div className={"filterTags"}>

                                    {filterSizes.map((value, index) => {
                                      console.log(value,activeFilterSizes );
                                        if(activeFilterSizes.includes(value.uuid)){
                                            return    <div className={"filterTag"}>
                                                {value.name}
                                                <span  onClick={() => removeFilterGroup(value.uuid,"volumes")} className={"close"}>&nbsp;&nbsp;x</span>
                                            </div>
                                        }
                                    })}

                                </div>
                                : null}
                        </div>


                    </>
                    : null
                }


           </div>

        </StyledProductFilter>

            <DialogGroupsModalEditAdd
                modalTransitionShow={modalTransitionShow}
                editAllModalActive={editAllModalActive}
                editionsModalActive={editionsModalActive}
                clientModalActive={clientModalActive}
                brandModalActive={brandModalActive}
                editAllName={editAllName}
                editAllTypeHeaderText={editAllTypeHeaderText}
                editAllType={editAllType}
                editAllIcon={editAllIcon}
                editAllUUID={editAllUUID}
                setModalShow={setModalShow}
                changeEditAllName={changeEditAllName}
                filterClients={allFilterDataAPI && allFilterDataAPI.values ? allFilterDataAPI.values.clients : null}
                loadProductsFilters={loadProductsFiltersAPI}


                filterBrands={filterBrands}
                closeModalOutsideClickEdit={closeModalOutsideClickEdit}
            />






            <ModalsWrapper
                className={` ${modalTransitionShowActiveFilterSide ? 'showTransition' : 'hideTransition'} ${(activeFilterSide) ? '' +
                    '' : 'hiddeComplete'} `} style={{zIndex:"999999999",overflow:"hidden"}}
                data-closemodal={true}
                onClick={(e)=> closeModalOutsideClick(e)}
          >
                <ModalWindowRightSide

                    className={`deleteCheck center  ${activeFilterSide ? '' : 'hideModal'}`}>
                <div>


                    <div className={'modal-header'}>
                        <div className="MuiBox-root-7 jss1517 jss359">
                            <h3
                                style={{  marginBottom: "15px!important"}}
                                className="MuiTypography-root-106 MuiTypography-h3-113 MuiTypography-colorPrimary-129">{openedFilterName}</h3>

                              <FontAwesomeIcon  style={{    position: "absolute", right: "30px",  top: "45px"}} onClick={() => changeActiveFilterSide(false)} icon={faX}/>

                        </div>

                    </div>


                    {filterClientsOpened ?
                        <GlobalButtonFinal
                            onClick={() => setModalShow(true, "client")}
                            id={"addNewClientBtn"}
                            style={{    width: "40%",  paddingLeft:"10px",paddingRight:"10px",  fontSize: "15px",  marginBottom: "30px"}}
                            className={"borderLightGray btn-circle"}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight:"5px"}}

                            />Add new client
                        </GlobalButtonFinal>
                        : null}
                    {filterBrandsOpened ?
                        <GlobalButtonFinal
                            onClick={() => setModalShow(true, "brand")}
                            style={{    width: "40%",  paddingLeft:"10px",paddingRight:"10px",  fontSize: "15px",  marginBottom: "30px"}} className={"borderLightGray btn-circle"}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight:"5px"}}

                            />Add new brand
                        </GlobalButtonFinal>
                        : null}
                    {filterEditionsOpened ?
                        <GlobalButtonFinal
                        onClick={() => setModalShow(true, "edition")}
                        style={{    width: "40%",  paddingLeft:"10px",paddingRight:"10px",  fontSize: "15px",  marginBottom: "30px"}} className={"borderLightGray btn-circle"}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight:"5px"}}

                        />Add new edition
                    </GlobalButtonFinal>
                        : null}
               
                  



                    <div className={'modal-content '} id={"groupsContent"} style={{display:"block", width :"100%"}}>

                        {filterClientsOpened ? <>
                            <ul>



                                {allFilterDataAPI.values.clients !== undefined && filterClients.length > 0 ? (
                                 <>
                                        {allFilterDataAPI.values.clients.map((value, index) => {
                                            return (
                                                <li
                                                    key={'filter-clients-'+index}
                                                    className={filteredValues.clients && filteredValues.clients.includes(value.uuid) ? '' : 'disabledFilter'}

                                                >
                                                    <div className={"logoInput"}>
                                                        <input className="jss1595"
                                                               tabIndex="-1"
                                                               type="checkbox"
                                                               data-indeterminate="false"
                                                               checked={value.checked}
                                                               disabled={filterActive ? (filteredValues.clients.includes(value.uuid) ? false : true) : false}
                                                               onClick={() => onChangeAddFilter("clients", value.uuid)}
                                                            // onClick={ e => chooseClientFilterFunction(e, value.uuid, value.name) }
                                                        />

                                                    </div>
                                                        <div className={"content"}   onClick={() => onChangeAddFilter("clients", value.uuid)}>
                                                            <div className={"logoInside"} >
                                                                {value.logo ?
                                                                    <img src={value.logo }  />
                                                                    :    <FontAwesomeIcon className="newIcon" icon={faMugHot}/>}

                                                            </div>

                                                            <p>{value.name}</p>

                                                        </div>

                                                    <div className={"content-buttons"}>

                                                        <button className={`optionsFilterItem`}
                                                                onClick={() => startEditAnything("client", value.uuid, value.name, value.logo)}
                                                        >  <FontAwesomeIcon icon={faPen}/></button>
                                                        <button className={`optionsFilterItem`}
                                                                onClick={() => showDelete(value.uuid)}
                                                        >  <FontAwesomeIcon icon={faTrash}/></button>

                                                    </div>



                                                </li>
                                            );
                                        })}
                                     <div className={'modal-buttons'} style={{display:"block", width :"100%"}}>
                                         <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>Show results</GlobalButtonFinal>
                                         <GlobalButtonFinal  onClick={() => onChangeAddFilter("clients", null)} className={'btn-circle borderLightGray'} style={{marginLeft:"5px"}}>Clear filter</GlobalButtonFinal>
                                     </div>
                                 </>
                                ) : null}
                            </ul>

                        </>: null}
                        {filterBrandsOpened ?
                            <ul>

                                {allFilterDataAPI.values.brands !== undefined && allFilterDataAPI.values.brands.length > 0 ? (
                                    <>
                                        {allFilterDataAPI.values.brands.map((value, index) => {
                                            return (
                                                <li
                                                    key={'filter-brand-'+index}
                                                    className={filteredValues.brands && filteredValues.brands.includes(value.uuid) ? '' : 'disabledFilter'}

                                                >
                                                    <div className={"logoInput"}>
                                                        <input className="jss1595"
                                                               tabIndex="-1"
                                                               type="checkbox"
                                                               data-indeterminate="false"
                                                               checked={value.checked}
                                                               disabled={filterActive ? (filteredValues.brands.includes(value.uuid) ? false : true) : false}
                                                               onClick={() => onChangeAddFilter("brands", value.uuid)}
                                                        />

                                                    </div>
                                                    <div className={"content"} onClick={() => onChangeAddFilter("brands", value.uuid)}>
                                                        <div className={"logoInside"}
                                                        >
                                                            {value.logo ?
                                                                <img src={value.logo }  />
                                                                :    <FontAwesomeIcon className="newIcon" icon={faMugHot}/>}

                                                        </div>


                                                        <p>{value.name}</p>

                                                    </div>

                                                    <div className={"content-buttons"}>

                                                        <button className={`optionsFilterItem`}
                                                                onClick={() => startEditAnything("brand", value.uuid, value.name, value.logo)}
                                                        >  <FontAwesomeIcon icon={faPen}/></button>
                                                        <button className={`optionsFilterItem`}
                                                                onClick={() => showDelete(value.uuid)}
                                                        >  <FontAwesomeIcon icon={faTrash}/></button>

                                                    </div>



                                                </li>
                                            );
                                        })}
                                        <div className={'modal-buttons'} style={{display:"block", width :"100%"}}>
                                            <GlobalButtonFinal className={'btn-circle backgroundBlue'}  onClick={() => showFilteredProducts()}>Show results</GlobalButtonFinal>
                                            <GlobalButtonFinal className={'btn-circle borderLightGray'}onClick={() => onChangeAddFilter("clients", null)} style={{marginLeft:"5px"}}>Clear filter</GlobalButtonFinal>
                                        </div>
                                    </>
                                ) : null}
                            </ul>
                            : null}
                        {filterEditionsOpened ?   <ul>

                            {allFilterDataAPI.values.editions !== undefined && allFilterDataAPI.values.editions.length > 0 ? (
                                <>
                                    {allFilterDataAPI.values.editions.map((value, index) => {
                                        return (
                                            <li
                                                key={'filter-editions-'+index}
                                                className={filteredValues.editions && filteredValues.editions.includes(value.uuid) ? '' : 'disabledFilter'}

                                            >
                                                <div className={"logoInput"}>
                                                    <input className="jss1595"
                                                           tabIndex="-1"
                                                           type="checkbox"
                                                           data-indeterminate="false"
                                                           checked={value.checked}
                                                           disabled={filterActive ? (filteredValues.editions.includes(value.uuid) ? false : true) : false}
                                                           onClick={() => onChangeAddFilter("editions", value.uuid)}
                                                    />

                                                </div>
                                                <div className={"content"}  onClick={() => onChangeAddFilter("editions", value.uuid)}>
                                                    <div className={"logoInside"}  >
                                                        {value.logo ?
                                                            <img src={value.logo }  />
                                                            :    <FontAwesomeIcon className="newIcon" icon={faMugHot}/>}

                                                    </div>
                                                    <p>{value.name}</p>

                                                </div>

                                                <div className={"content-buttons"}>

                                                    <button className={`optionsFilterItem`}
                                                            onClick={() => startEditAnything("edition", value.uuid, value.name, value.logo)}
                                                    >  <FontAwesomeIcon icon={faPen}/></button>
                                                    <button className={`optionsFilterItem`}
                                                            onClick={() => showDelete(value.uuid)}
                                                    >  <FontAwesomeIcon icon={faTrash}/></button>

                                                </div>



                                            </li>
                                        );
                                    })}
                                    <div className={'modal-buttons'} style={{display:"block", width :"100%"}}>
                                        <GlobalButtonFinal className={'btn-circle backgroundBlue'}  onClick={() => showFilteredProducts()}>Show results</GlobalButtonFinal>
                                        <GlobalButtonFinal className={'btn-circle borderLightGray'} onClick={() => onChangeAddFilter("clients", null)} style={{marginLeft:"5px"}}>Clear filter</GlobalButtonFinal>
                                    </div>
                                </>
                            ) : null}
                        </ul>
                            : null}
                        {filterProductTypesOpened ?  <ul>

                            {allFilterDataAPI.values.product_types !== undefined && allFilterDataAPI.values.product_types.length > 0 ? (
                                <>
                                    {allFilterDataAPI.values.product_types.map((value, index) => {
                                        console.log(value, "VALUE product_types", filteredValues.product_types);
                                        return (
                                            <li
                                                key={'filter-productType-'+index}
                                                className={filteredValues.product_types && filteredValues.product_types.includes(value.uuid) ? '' : 'disabledFilter'}

                                            >
                                                <div className={"logoInput"}>
                                                    <input className="jss1595"
                                                           tabIndex="-1"
                                                           type="checkbox"
                                                           data-indeterminate="false"
                                                           checked={value.checked}
                                                           disabled={filterActive ? (filteredValues.product_types.includes(value.uuid) ? false : true) : false}
                                                           onClick={() => onChangeAddFilter("product_types", value.uuid)}
                                                    />

                                                </div>
                                                <div className={"content"}  onClick={() => onChangeAddFilter("product_types", value.uuid)}>

                                                    <p>{value.name}</p>

                                                </div>




                                            </li>
                                        );
                                    })}
                                    <div className={'modal-buttons'} style={{display:"block", width :"100%"}}>
                                        <GlobalButtonFinal className={'btn-circle backgroundBlue'}  onClick={() => showFilteredProducts()}>Show results</GlobalButtonFinal>
                                        <GlobalButtonFinal className={'btn-circle borderLightGray'} onClick={() => onChangeAddFilter("clients", null)} style={{marginLeft:"5px"}}>Clear filter</GlobalButtonFinal>
                                    </div>
                                </>
                            ) : null}
                        </ul>: null}
                        {filterSizesOpened ?
                            <ul>

                                {allFilterDataAPI.values.volumes !== undefined && allFilterDataAPI.values.volumes.length > 0 ? (
                                    <>
                                        {allFilterDataAPI.values.volumes.map((value, index) => {
                                            console.log(value, "VALUE SIZES", filteredValues.volumes);
                                            return (
                                                <li
                                                    key={'filter-size-'+index}
                                                    className={filteredValues.volumes && filteredValues.volumes.includes(value.uuid) ? '' : 'disabledFilter'}

                                                >
                                                    <div className={"logoInput"}>
                                                        <input className="jss1595"
                                                               tabIndex="-1"
                                                               type="checkbox"
                                                               data-indeterminate="false"
                                                               checked={value.checked}
                                                               disabled={filterActive ? (filteredValues.volumes.includes(value.uuid) ? false : true) : false}
                                                               onClick={() => onChangeAddFilter("volumes", value.uuid)}
                                                        />

                                                    </div>
                                                    <div className={"content"}>

                                                        <p>{value.name}</p>
                                                    
                                                    </div>



                                                </li>
                                            );
                                        })}
                                        <div className={'modal-buttons'} style={{display:"block", width :"100%"}}>
                                            <GlobalButtonFinal className={'btn-circle backgroundBlue'}  onClick={() => showFilteredProducts()}>Show results</GlobalButtonFinal>
                                            <GlobalButtonFinal className={'btn-circle borderLightGray'} onClick={() => onChangeAddFilter("clients", null)} style={{marginLeft:"5px"}}>Clear filter</GlobalButtonFinal>
                                        </div>
                                    </>
                                ) : null}
                            </ul>
                            : null}
                    </div>




                </div>


                </ModalWindowRightSide>

            </ModalsWrapper>
            <DialogDeleteGroups
                editAllUUID={editAllUUID}
                toggleDelete={ (toggleDelete: () => void) => {
                    handleClickDelete = toggleDelete;
                } }
                loadProductsFiltersAPI={loadProductsFiltersAPI}
                changeEditAllUUID={changeEditAllUUID}
                triggerWarning={triggerWarning}

            />


            <DialogAlert
                toggleAlert={ (toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                } }
                alertType={"error"}
                message={warningMessage}
            />


        </>
    );
}
