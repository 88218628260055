import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';

import styled from 'styled-components';

import Products from './Products';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Container, Row } from 'react-bootstrap';
import Link from 'next/link';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../global/GlobalStyles';
import DialogAcceptTeamInvite from '../global/dialogs/DialogAcceptTeamInvite';
import { getCookie, deleteCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';

const StyledDashboard = styled.div`
    height: 100%;

    @media (max-width: 600px) {
        section {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
`;

export const LoaderProducts = styled.div`
    z-index: 9999999;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    left: 0;
    top: 5px;

    width: 100%;
    height: 100%;

    //@media(max-width:768px){
    //    top:65px;
    //}

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        //width:100px;
        max-height: 60px !important;
    }

    transition: all 0.5s ease;

    opacity: 0;
    display: block;
    &.showTransition {
        opacity: 1;
    }
    &.hideTransition {
        opacity: 0;
    }

    &.hiddeComplete {
        display: none;
    }
`;

export default function Dashboard({
    props: any,
    openDuplicateDialogAndSetIDZ,
    openDeleteDialogAndSetIDZ,
    toggleReload,
     changeLoadingShowTop,
    openDeleteDialogAndSetIDZMultiple,
    openMoveDialogAndSetIDZ,
    openRenameDialogAndSetIDZ,
    deleteProductCheck,
    toggle,
    closeDeleteShow,
    productDeleteUUID,
    changeProductDeleteUUID,
    trigger,
}) {
    const router = useRouter();
    const [session, loading] = useSession();
    if (typeof window !== 'undefined' && loading) return null;

    if (!loading && !session) {
        const router = useRouter();
        router.push('/');
        return null;
    }

    const [isMediumDisplay, setMediumDisplay] = useState(false);

    const [teamInvitationUUID, setTeamInvitationUUID] = useState(false);

    let handleClickInvite = () => {};

    //

    useEffect(() => {
        document.getElementById('mainBody').classList.add('gray');
        if (teamInvitationUUID) {
            handleClickInvite();
        }
    }, [teamInvitationUUID]);

    //
    useEffect(() => {
        if (router.query.teamInvitation) {
            setTeamInvitationUUID(router.query.teamInvitation);
        }
        if (window.innerWidth > 1310) {
            setMediumDisplay(true);
        } else {
            setMediumDisplay(false);
        }

        const updateMedia = () => {
            if (window.innerWidth > 1310) {
                setMediumDisplay(true);
            } else {
                setMediumDisplay(false);
            }
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    const [loadProductsShow, setLoadProductsShowVariable] = useState(true);
    const [loadTransitionProductsShow, setLoadTransitionProductsShow] = useState(true);

    const setLoadProductsShow = variable => {
        if (variable === true) {
            setLoadProductsShowVariable(variable);
            setLoadTransitionProductsShow(true);
        } else {
            setTimeout(function () {
                setLoadTransitionProductsShow(false);
                setTimeout(function () {
                    setLoadProductsShowVariable(variable);
                }, 500);
            }, 300);
        }
    };

    ///////////// NEW PRODUCT

    const [newProductTypes, setNewProducTypes] = useState([]);

    const ListProductTypes = () => {
        var allMainProductGroups = [];
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'package-groups', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + session.accessToken, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                //   console.log("PRODUCT TYPES",data);

                if (data.message !== undefined) {
                    Sentry.captureException('package-groups' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                data.map(item => {
                    //  console.log("ITEM ", item);
                    if (item.parent === null) {
                        allMainProductGroups.push(item);
                    }
                });
                setNewProducTypes(allMainProductGroups);
            });
    };

    useEffect(() => {
        if (getCookie('reloadProducts') === true) {
            deleteCookie('reloadProducts');
            location.reload();
        }
        ListProductTypes();
    }, []);

    const [newproductsShow, setNewProductsShow] = useState(false);
    const [newproductsTransitionShow, setNewProductsTransitionShow] = useState(false);

    const changeNewProductsShow = variable => {
        if (variable === true) {
            setNewProductsShow(variable);
            setTimeout(function () {
                setNewProductsTransitionShow(true);
            }, 1);
        } else {
            setNewProductsTransitionShow(false);
            setTimeout(function () {
                setNewProductsShow(variable);
            }, 500);
        }
    };

    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeNewProductsShow(false);
        }
    };

    return (
        <>
            <StyledDashboard id={'dashboard'} data-closemodalmenu={true}>
                <section className="small-section bg-gray-lighter pt-30 pb-0" data-closemodalmenu={true} style={{ overflow: 'visible' }}>
                    <Container>
                        <Row>
                            <Col md={12} sm={12} xs={12} lg={12} style={{ position: 'relative' }} data-closemodalmenu={true} className={'section-header'}>
                                <h1 className="mb-20 mb-xs-0" style={{ display: 'inline-block' }}>
                                    Products
                                </h1>

                                <Link href={'/new-product'}>
                                    <GlobalButtonFinal
                                        style={{ float: 'right' }}
                                        className={'btn-circle backgroundBlue'}
                                    >
                                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faPlus} />
                                        New
                                    </GlobalButtonFinal>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className={'page-section pt-0 bg-gray-lighter'} data-closemodalmenu={true} style={{ paddingBottom: ' 220px' }}>
                    <Container>
                        <Row>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <Products
                                    openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                                    openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                                    openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                                    openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                                    toggleReload={toggleReload}
                                    openDeleteDialogAndSetIDZMultiple={openDeleteDialogAndSetIDZMultiple}
                                    changeLoadingShowTop={changeLoadingShowTop}
                                    setLoadProductsShow={setLoadProductsShow}
                                    closeDeleteShow={closeDeleteShow}
                                    trigger={trigger}
                                    deleteProductCheck={deleteProductCheck}
                                    productDeleteUUID={productDeleteUUID}
                                    changeProductDeleteUUID={changeProductDeleteUUID}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <ModalsWrapper
                    className={`deleteCheck  ${newproductsTransitionShow ? 'showTransition' : 'hideTransition'} ${!newproductsShow ? 'hiddeComplete' : ''}`}
                    data-closemodal={true}
                    onClick={e => closeModalOutsideClick(e)}
                >
                    <ModalWindow className={'center'} id={'newproductsProductDialog'}>
                        <div className={'modal-header'}>
                            <h4>Create new product</h4>
                        </div>

                        <div className={'modal-content shareProduct'} style={{ display: 'block' }}>
                            <span>Select your package type</span>

                            <div className={'packageTypes'}>
                                <Link href={'new-product'} id={'newCansID'}>
                                    <div>
                                        <img src={'/images/cans.jpg'} />
                                        <h5 className="text-center mb-0 mt-1">Cans</h5>
                                    </div>
                                </Link>

                                {/*<Link href={'new-product/e9b2c97b-5931-4bdb-9637-26472a387750'}>*/}
                                {/*    <div>*/}
                                {/*        <img src={'/images/combibloc.jpg'} />*/}
                                {/*        <h5 className="text-center mb-0 mt-1">SIG Combibloc</h5>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}

                                {/*<Link href={'new-product/1167f03e-aa62-4616-91e7-134a38d99dec'}>*/}
                                {/*    <div>*/}
                                {/*        <img src={'/images/tetrapak.jpg'} />*/}
                                {/*        <h5 className="text-center mb-0 mt-1">Tetrapak</h5>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}

                                {/*<Link href={'new-product/87cbd100-87ad-4ec4-b39c-812c18bb644b'} style={{ marginTop: '40px' }}>*/}
                                {/*    <div>*/}
                                {/*        <img src={'/images/shrink.jpg'} />*/}
                                {/*        <h5 className="text-center mb-0 mt-1">Shrink Foils</h5>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}
                            </div>

                            <div className={'modal-buttons'}>

                                <GlobalButtonFinal style={{ width: 'fit-content' }} className={'borderBlue btn-circle'} onClick={() => changeNewProductsShow(false)}>
                                    Cancel
                                </GlobalButtonFinal>
                            </div>
                        </div>
                    </ModalWindow>
                </ModalsWrapper>

                <DialogAcceptTeamInvite
                    toggleInvite={(toggleInvite: () => void) => {
                        handleClickInvite = toggleInvite;
                    }}
                    inviteTeamUUID={teamInvitationUUID}
                />

                <LoaderProducts className={`dashboard-products-loader-1-dashboard ${loadTransitionProductsShow ? 'showTransition' : 'hideTransition'} ${!loadProductsShow ? 'hiddeComplete' : ''} `}>
                    <img src={'/logoAnimate.svg'} />
                </LoaderProducts>
            </StyledDashboard>
        </>
    );
}
