import React, { useCallback, useEffect, useRef, useState } from 'react';
import Product from '../pages/products/Product';

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { StyledProductsContent, StyledPagination } from '../pages/products/styles/ProductsStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { Container, Row } from 'react-bootstrap';
import GroupsFilter from '../pages/products/GroupsFilter';
import * as Sentry from '@sentry/nextjs';

SwiperCore.use([Navigation, Pagination, A11y]);

export default function Products({
    props: any,
    plugin, changeLoadingShowTop,
    openDuplicateDialogAndSetIDZ,
    openDeleteDialogAndSetIDZ,
    openDeleteDialogAndSetIDZMultiple,
    openMoveDialogAndSetIDZ,
    openRenameDialogAndSetIDZ,
    deleteProductCheck,
    setLoadProductsShow,
    toggleReload,
}) {
    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [products, setProducts] = useState([]);

    const [loadinProductsComplete, setLoadinProductsComplete] = useState(false);

    const toggleReloadInside = useCallback((): void => {
        console.log("LOAD PPRODCUCTS");
        loadProducts(currentPage);
    }, []);
    useEffect(() => {
        toggleReload && toggleReload(toggleReloadInside);
    }, [toggleReload, toggleReloadInside]);

    const loadProducts = (pageValue, filters) => {
        var filtersInternal = {};

        if (filters) {
            filtersInternal = filters;
            setFilterActive(true);
        }

        let filtersVariableStringClients = '&client=';
        let filtersVariableStringBrands = '&brand=';
        let filtersVariableStringEditions = '&edition=';
        let filtersVariableStringProductTypes = '&product_type=';
        let filtersVariableStringPackages = '&package=';
        let filtersVariableStringVolumes = '&volume=';

        if (filtersInternal.clients) {
            filtersVariableStringClients += filtersInternal.clients.toString();
        } else {
            filtersVariableStringClients = '';
        }
        if (filtersInternal.brands) {
            filtersVariableStringBrands += filtersInternal.brands.toString();
        } else {
            filtersVariableStringBrands = '';
        }
        if (filtersInternal.editions) {
            filtersVariableStringEditions += filtersInternal.editions.toString();
        } else {
            filtersVariableStringEditions = '';
        }
        if (filtersInternal.product_types) {
            filtersVariableStringProductTypes += filtersInternal.product_types.toString();
        } else {
            filtersVariableStringProductTypes = '';
        }
        if (filtersInternal.packages) {
            filtersVariableStringPackages += filtersInternal.packages.toString();
        } else {
            filtersVariableStringPackages = '';
        }
        if (filtersInternal.volumes) {
            filtersVariableStringVolumes += filtersInternal.volumes.toString();
        } else {
            filtersVariableStringVolumes = '';
        }

        var fullVariableString =
            filtersVariableStringClients +
            filtersVariableStringBrands +
            filtersVariableStringEditions +
            filtersVariableStringProductTypes +
            filtersVariableStringPackages +
            filtersVariableStringVolumes;

        neco = 0;
        setLoadProductsShow(true);

        var page = 1;
        if (pageValue) {
            page = pageValue;
        }
        var commT;

        if (plugin) {
            commT = getCookie('pluginLoginSessionTeamToken');
        } else {
            commT = getCookie('userTeam');
        }
        // console.log("LOAD PRODUCTS", page);
        var allProductsArray = [];

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'products?page=' + page + fullVariableString, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + commT, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                console.log(' PRODUCTS', data);

                if (data.message !== undefined) {
                    Sentry.captureException('products?page=' + page + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                setCurrentPage(page);

                allProductsArray = data.data;
                setTotalPages(data.pages);
                var NewDataProducts = [];
                for (var product in allProductsArray) {
                    allProductsArray[product].productSelected = false;
                    NewDataProducts.push(allProductsArray[product]);
                }
                changeLoadingShowTop(false);
                setLoadProductsShow(false);
                setProducts(NewDataProducts);
                window.scrollTo({ top: 0, left: 0 });

                console.log('PRODUCS END', NewDataProducts, setLoadProductsShow, NewDataProducts.length);
                setLoadinProductsComplete(true);
            });
    };

    const [filterClients, setFilterClients] = useState([]);
    const [filterBrands, setFilterBrands] = useState([]);
    const [filterEditions, setFilterEditions] = useState([]);

    var neco = 0;
    const [threeInited, setThreeInited] = useState(false);

    const [filteredProductsState, setFilteredProductsState] = useState([]);

    const [filterActive, setFilterActive] = useState(false);
    // const [filterActive, setFilterActive] = useState(false);
    const [filteredProductsTemp, setFilteredProductsTemp] = useState([]);
    const setFilteredProducts = filteredProducts => {
        setFilteredProductsTemp(filteredProductsTemp);
    };
    const [selectingMultiple, setSelectingMultiple] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState([]);

    const onLoadProductThumb = state => {
        //console.log("COUNTING NECO ", neco, products.length);
        neco = neco + 1;
        if (neco === products.length) {
            setLoadProductsShow(false);

            setThreeInited(true);
        }
    };

    const selectingFunction = e => {
        //   console.log("SELECTING", e);
        e.preventDefault();
        if (selectingMultiple) {
            if (!selectedProducts.includes(e.target.getAttribute('data-productid'))) {
                addToSelectedProducts(e.target.getAttribute('data-productid'));
            } else {
                removeToSelectedProducts(e.target.getAttribute('data-productid'));
            }
        }
    };

    const openDeleteMultiple = () => {
        setSelectingMultiple(false);
        openDeleteDialogAndSetIDZMultiple(selectedProducts);
    };
    const stopSelectingMultiple = () => {
        let productsNewArray = [];
        let productsNew = [
            ...products.map(item => {
                item.productSelected = false;
                productsNewArray.push(item);
            }),
        ];
        setProducts(productsNewArray);

        setSelectingMultiple(false);
        setSelectedProducts([]);
    };

    const addToSelectedProducts = replace => {
        let selectedProductsNew = [...selectedProducts, replace];
        let productsNewArray = [];

        let productsNew = [
            ...products.map(item => {
                if (item.uuid === replace) {
                    item.productSelected = true;
                }
                productsNewArray.push(item);
            }),
        ];

        setSelectedProducts(selectedProductsNew);
        setProducts(productsNewArray);
    };

    const removeToSelectedProducts = replace => {
        let selectedProductsNew = [...selectedProducts.filter(selectedProduct => selectedProduct !== replace)];
        let productsNewArray = [];

        let productsNew = [
            ...products.map(item => {
                if (item.uuid === replace) {
                    item.productSelected = false;
                }
                productsNewArray.push(item);
            }),
        ];

        setSelectedProducts(selectedProductsNew);
    };

    useEffect(() => {
        loadProducts();
    }, []);
    //

    return (
        <div style={{ padding: '0', paddingTop: '15px' }}>
            {}
            {products.length === 0 && !filterActive ? (
                <div>
                    <Container>
                        <Row style={{ paddingBottom: '50px', paddingTop: '50px' }}>
                            <h2 style={{ textAlign: 'center', position: 'relative' }}>Start your perfect design by choosing your package type</h2>
                        </Row>
                        <Row>
                            <div className="welcomeProduct">
                                <Link href={'new-product'}>
                                    <img
                                        style={{
                                            maxWidth: '300px',
                                            top: '-15px',
                                            position: 'relative',
                                            left: ' 0px',
                                        }}
                                        src={'/images/cans.jpg'}
                                    />
                                    <h3 style={{ position: 'relative', top: '5px' }} className="text-center mb-0 mt-1">
                                        Cans
                                    </h3>
                                </Link>
                            </div>
                            {/*<div className="welcomeProduct">*/}
                            {/*    <Link href={'newproduct/e9b2c97b-5931-4bdb-9637-26472a387750'}>*/}
                            {/*        <div className="welcomeProduct">*/}
                            {/*            <img*/}
                            {/*                style={{*/}
                            {/*                    maxWidth: '300px',*/}
                            {/*                    top: '-15px',*/}
                            {/*                    position: 'relative',*/}
                            {/*                    left: ' 0px',*/}
                            {/*                }}*/}
                            {/*                src={'/images/combibloc.jpg'}*/}
                            {/*            />*/}
                            {/*            <h3 style={{ position: 'relative', top: '5px' }} className="text-center mb-0 mt-1">*/}
                            {/*                SIG Combibloc*/}
                            {/*            </h3>*/}
                            {/*        </div>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            {/*<div className="welcomeProduct">*/}
                            {/*    <Link href={'newproduct/1167f03e-aa62-4616-91e7-134a38d99dec'}>*/}
                            {/*        <div className="welcomeProduct">*/}
                            {/*            <img*/}
                            {/*                style={{*/}
                            {/*                    maxWidth: '300px',*/}
                            {/*                    top: '-15px',*/}
                            {/*                    position: 'relative',*/}
                            {/*                    left: ' 0px',*/}
                            {/*                }}*/}
                            {/*                src={'/images/tetrapak.jpg'}*/}
                            {/*            />*/}
                            {/*            <h3 style={{ position: 'relative', top: '5px' }} className="text-center mb-0 mt-1">*/}
                            {/*                Tetrapak*/}
                            {/*            </h3>*/}
                            {/*        </div>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            {/*<div className="welcomeProduct">*/}
                            {/*    <Link href={'newproduct/87cbd100-87ad-4ec4-b39c-812c18bb644b'} style={{ marginTop: '40px' }}>*/}
                            {/*        <img*/}
                            {/*            style={{*/}
                            {/*                maxWidth: '300px',*/}
                            {/*                top: '-15px',*/}
                            {/*                position: 'relative',*/}
                            {/*                left: ' 0px',*/}
                            {/*            }}*/}
                            {/*            src={'/images/shrink.jpg'}*/}
                            {/*        />*/}
                            {/*        <h3 style={{ position: 'relative', top: '5px' }} className="text-center mb-0 mt-1">*/}
                            {/*            Shrink Foils*/}
                            {/*        </h3>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                        </Row>
                    </Container>
                </div>
            ) : (
                <>
                    <div style={{ position: 'relative', left: '0' }}>
                        <GroupsFilter
                            setFilterClientsProducts={setFilterClients}
                            setFilterBrandsProducts={setFilterBrands}
                            setFilterEditionsProducts={setFilterEditions}
                            products={products}
                            setProducts={setProducts}
                            filteredProducts={filteredProductsState}
                            setFilteredProducts={setFilteredProducts}
                            loadProducts={loadProducts}
                            filterActive={filterActive}
                            loadinProductsComplete={loadinProductsComplete}
                            setFilterActive={setFilterActive}
                        />
                    </div>
                </>
            )}

            <StyledProductsContent
                // className={filterClientsOpened ? 'offsetFilter' : ''}
                style={products.length === 0 ? { zIndex: '1', position: 'relative' } : null}
                id={'productsContent'}
            >
                <div id="content" style={{ textAlign: 'center' }} data-closemodalmenu={true}>
                    {products.length > 0 && products !== undefined ? (
                        <>
                            {products.map((value, index) => {
                                return (
                                    <Product
                                        openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                                        openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                                        openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                                        openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                                        productType={'GRID'}
                                        withThumbnail={true}
                                        productThumbnail={value.last_updated_version.thumbnail.fallback}
                                        threeID={'threeGrid' + value.uuid}
                                        deleteProductCheck={deleteProductCheck}
                                        key={'Product' + index}
                                        productID={value.uuid}
                                        productSelected={value.productSelected}
                                        selectingMultiple={selectingMultiple}
                                        productName={value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}
                                        classes={`showProduct `}
                                        productBrand={filterBrands.map((valueBrands, index) => {
                                            if (valueBrands.uuid === value.groups.brand) {
                                                return valueBrands.name;
                                            }
                                        })}
                                        productClient={filterClients.map((valueClients, index) => {
                                            if (valueClients.uuid === value.groups.client) {
                                                return valueClients.name;
                                            }
                                        })}
                                        productEdition={filterEditions.map((valueEdition, index) => {
                                            if (valueEdition.uuid === value.groups.edition) {
                                                return valueEdition.name;
                                            }
                                        })}
                                        productVersions={value.versions.length}
                                        productVersionsList={value.versions}
                                        productVolume={value.package.volume.name}
                                        productPackageGroup={value.package.groups[0].name}
                                        productPackageLastUUID={value.last_updated_version.uuid}
                                        clickEvent={e => selectingFunction(e)}
                                    />
                                );
                            })}
                        </>
                    ) : null}
                </div>
            </StyledProductsContent>

            {totalPages > 1 ? (
                <StyledPagination className={'pagination'}>
                    {currentPage > 1 ? (
                        <button onClick={() => loadProducts(currentPage - 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronLeft} />{' '}
                        </button>
                    ) : null}

                    <span>
                        {currentPage} of {totalPages}
                    </span>

                    {currentPage < totalPages ? (
                        <button onClick={() => loadProducts(currentPage + 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronRight} />{' '}
                        </button>
                    ) : null}
                </StyledPagination>
            ) : null}
        </div>
    );
}
