import React, {  useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck
} from '@fortawesome/pro-regular-svg-icons';
export const StyledFileUploader = styled.div`
justify-content: center;
align-items: stretch;
border-radius: 11px;
border-color: rgba(102, 102, 102, 1) !important;
border-style: dashed !important;
border-width: 1px!important;
display: flex;
margin-top: 30px;
padding-left: 20px;
padding-right: 20px;
padding-top: 50px;
padding-bottom: 50px;
flex-direction: column;
    
    &.isSmall{
        padding-top: 30px;
        padding-bottom: 30px;
    }
  
`;
export default function ObaleroFileUploader ({setFile, file, isSmall,isGroup, closeModal}) {


    const [draggedOver, setDraggedOver] = useState(false);



    const handleDragOver = (event) => {
        event.preventDefault();
        setDraggedOver(true);
    };
    const handleDragLeave = (event) => {
        event.preventDefault();
        setDraggedOver(false);
    };


    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files[0];

        // const newFiles = Array.from(droppedFiles);
        setFile(droppedFiles);


        setDraggedOver(false);
        if(isGroup){
            closeModal(false);
        }

    };

    const handleChangeTexture = ((e: React.ChangeEvent<HTMLInputElement>): void => {

        setFile(e.target.files[0]);
        if(isGroup){
            closeModal(false);
        }

    });
    const inputFile = useRef(null);
    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    return (

            <StyledFileUploader

                className={`fileUploadEditor  ${draggedOver ? "activeDrag" : ""}
                  ${isSmall ? "isSmall" : ""}`}


                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >

                <div>
                    {file ?
                        <span
                            className="not-italic text-[color:var(--black-60,#666)] max-md:max-w-full">
                                                          <FontAwesomeIcon icon={faCheck}/>  File selected
                                                        </span>
                        : <span
                            className="not-italic text-[color:var(--black-60,#666)] max-md:max-w-full">
                                                            Drop your file here or click to
                                                        </span>}


                    <button
                        onClick={onButtonClick}
                        className="flex flex-col justify-center self-center px-9 py-1.5 mt-2.5 not-italic whitespace-nowrap rounded-2xl bg-[color:var(--Buton-Active,#3D55D7)] text-[color:var(--White,#FFF)] max-md:px-5">
                        {file ? "Change file ...": "Choose file..."}
                    </button>

                    <input type="file" id="file"
                           ref={inputFile}
                           onChange={e => handleChangeTexture(e)} style={{ display: 'none' }} />

                </div>
            </StyledFileUploader>

    )
}