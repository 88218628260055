import React, { useState } from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import {
    faCheck,
    faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/nextjs';
import ObaleroFileUploader from '../ObaleroFileUploader';


export default function DialogGroupsModalEditAdd ({closeModalOutsideClickEdit,modalTransitionShow,
                                                      editAllModalActive,
                                                      editionsModalActive,
                                                      clientModalActive,
                                                      brandModalActive,
                                                      editAllName,
                                                      editAllTypeHeaderText,
                                                      editAllType,
                                                      editAllIcon,
                                                      editAllUUID,
                                                      setModalShow,
                                                      loadProductsFilters,
                                                      filterClients,
                                                      changeEditAllName,
                                                      filterBrands,
                                                      parentClient,
                                                      handleParentClient,
                                                      parentBrand
}) {


    const onChangeHandlerEdit = ((e: React.ChangeEvent<HTMLInputElement>): void => {
        if(e.currentTarget.value.length < 14){
            changeEditAllName(e.currentTarget.value);
        }
    });





    const fileTypes = ["JPG", "PNG"];
    const [file, setFile] = useState(null);

    function editAPIUploadLogo() {

        if(file !== null){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"uploads/prepare-form", {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){

                    if(data.message !== undefined){
                        Sentry.captureException("uploads/prepare-form"+": "+ data.message + " : " + getCookie("userMail"));
                    }



                    let formData = new FormData();

                    Object.keys(data.formInputs).forEach((key) => {
                        formData.append(key, data.formInputs[key]);
                    });

                    formData.append('file', file);

                    fetch(data.formAttributes.action, {
                        method: data.formAttributes.method,
                        body: formData,
                    })
                        .then(function(dataUpload){
                            editAPI("success", "/"+data.formInputs.key);

                        })
                        .catch(function(e){
                            Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                        });


                });
        } else {
            editAPI("success", null);
        }
    }


    const editAPI = (response, key) => {
        if(response === "success"){

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups/"+editAllUUID, {
                method: 'PUT',
                body: JSON.stringify(    {
                    name : editAllName,
                    logo : key,
                    type: editAllType
                    // parent:
                }),
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("edit groups/"+editAllUUID+": "+ data.message + " : " + getCookie("userMail"));
                    }

                    if(data.message === undefined){
                        changeEditAllName("");
                        setFile(null);
                       closeModalShow();
                        loadProductsFilters();


                    }

                });

        }
    }


    const closeModalShow = () => {
        setFile(null);
        setModalShow(false, "client");
        setModalShow(false, "brand");
        setModalShow(false, "edition");
        setModalShow(false, "editAll");
    };

    const handleChange = (file) => {
        setFile(file);
    };

    function AddGroup(groupType) {
        if(file !== null){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"uploads/prepare-form", {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){

                    if(data.message !== undefined){
                        Sentry.captureException("edit uploads/prepare-form"+": "+ data.message + " : " + getCookie("userMail"));
                    }

                    let formData = new FormData();

                    Object.keys(data.formInputs).forEach((key) => {
                        formData.append(key, data.formInputs[key]);
                    });
                    formData.append('file', file);

                    fetch(data.formAttributes.action, {
                        method: data.formAttributes.method,
                        // headers: {
                        //     'Content-type': data.formAttributes.enctype
                        // },
                        body: formData,
                    })
                        .then(function(dataUpload){
                            getResponseNewGroupAPI("success", "/"+data.formInputs.key);

                        })
                        .catch(function(e){
                            Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                        });


                    // uploadLogo(file, data.formInputs.key, groupType,getResponseNewGroupAPI)

                });
        } else {
            getResponseNewGroupAPI("success", null,groupType);
        }

    }


    const getResponseNewGroupAPI = (response, key, type) => {

        var parent = null;

        if(type === "brand"){
            parent = parentClient;
        }
        if(type === "edition"){
            parent = parentBrand;
        }

        if(response === "success"){

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups", {
                method: 'POST',
                body: JSON.stringify(    {
                    name : inputName,
                    logo : key,
                    type: type,
                    parent: parent
                }),
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("groups"+": "+ data.message + " : " + getCookie("userMail"));
                    }

                    if(data.message === undefined){
                        changeInputName("");
                        setFile(null);
                      closeModalShow();

                        loadProductsFilters();

                    }

                });
        }
    }

    const [inputName, changeInputName] = useState("");

    const onChangeHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
        if(e.currentTarget.value.length < 14){
            changeInputName(e.currentTarget.value);
        }
    });



    return (
        <>

            <ModalsWrapper
                data-closemodal={true}
                onClick={(e)=> closeModalOutsideClickEdit(e)}
                style={{    zIndex: "999999999999" }}
                className={` ${modalTransitionShow ? 'showTransition' : 'hideTransition'}
                 ${(editAllModalActive || editionsModalActive || clientModalActive||  brandModalActive) ? '' : 'hiddeComplete'} `}>

                <ModalWindow id={'editAllModal'} className={` modal_dialog_small ${editAllModalActive ? '' : 'hideModal'} `} >
                    <div className={'modal-header'} >
                        <h4>
                            Edit {editAllName}
                        </h4>
                    </div>


                    <div className={'modal-content editGroups'} style={{display:"block", paddingTop:"15px"}}>
                        <h5  style={{marginBottom: "0!important"}}>{editAllTypeHeaderText}</h5>
                        <input  style={{width: "97%"}}
                                id={"editGroupInput"}
                                onChange={onChangeHandlerEdit}
                                type={'text'} placeholder={editAllName}  value={editAllName} />

                        {editAllType === "brand" ?
                            <div style={{ width: '100%' }}>
                                <h5 style={{marginTop:"15px" }}>Client</h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClient}
                                        onChange={handleParentClient}
                                >
                                    {filterClients && filterClients.length > 0 ? (
                                        <>
                                            <option value={null}>{"No client"}</option>
                                            {filterClients.map((value, index) => {
                                                return <option value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>
                            : null}

                        {editAllType === "edition" ?
                            <div style={{ width: '100%' }}>
                                <h5 style={{marginTop:"15px" }}>Brand</h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentBrand}
                                        onChange={handleParentClient}
                                >
                                    {filterBrands && filterBrands.length > 0 ? (
                                        <>
                                            <option value={null}>{"No brand"}</option>
                                            {filterBrands.map((value, index) => {
                                                return <option value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>
                            : null}


                        <div style={{  width: "25%",   display: "inline-block", marginTop:"25px"}}>
                            <h5>Upload icon</h5>
                            {editAllIcon ?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIcon} />
                                : <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={"/images/obalero-icon.jpg"} />}

                        </div>


                        <div style={{  width: "71.6%",   display: "inline-block"}}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true}/>
                        </div>
                        <br/>
                        <span style={{textAlign:"left",marginTop:"15px", marginBottom:"15px"}}>or choose color</span>

                        <div className={"colors"}>
                            <button
                                style={{background: "#ffffff"}}
                            >
                            </button>
                            <button
                                style={{background: "#000000"}}
                            >
                            </button>
                            <button
                                style={{background: "#ff0000"}}
                            >
                            </button>
                            <button
                                style={{background: "#0300ff"}}
                            >
                            </button>
                            <button
                                style={{background: "#00ff1d"}}
                            >
                            </button>
                            <button
                                style={{background: "#ff9300"}}
                            >
                            </button>


                        </div>

                        <div className={"modal-buttons"} style={{  width: "100%", display:"block", textAlign: "center",position:"relative", marginTop :"15px", left:"-5px"}}>

                            <GlobalButtonFinal onClick={() =>  closeModalShow()} className={"borderBlue btn-circle"}
                                               >
                                Cancel
                            </GlobalButtonFinal>

                            <GlobalButtonFinal  onClick={() => editAPIUploadLogo()} className={"backgroundBlue btn-circle"}
                                                id={"editGroupSave"}
                                               >
                                <FontAwesomeIcon   icon={faCheck}/>  Save
                            </GlobalButtonFinal>

                        </div>

                    </div>
                </ModalWindow>


                <ModalWindow id={'clientModal'}
                             className={` modal_dialog_small ${clientModalActive ? '' : 'hideModal'} `}
                             style={{ minHeight: '330px' }}>
                    <div className={'modal-header'}>
                        <h4><FormattedMessage {...messages.products.modal.addnewclient}></FormattedMessage></h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>
                        <h5 style={{ marginTop: '5px' }}>Name</h5>
                        <input id={'addNewClientName'} type={'text'}
                               style={{ width: '97%' }}
                               value={inputName} onChange={onChangeHandler} />


                        <div style={{ width: '25%', display: 'inline-block', marginTop: '25px' }}>
                            <h5>Upload icon</h5>
                            {editAllIcon ?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIcon} />
                                : <img style={{ border: '1px solid lightgray', maxWidth: '100%' }}
                                       src={'/images/obalero-icon.jpg'} />}

                        </div>


                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                        <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}>or choose color</span>

                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>


                        <div className={'modal-buttons'} style={{ width: '100%' }}>
                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                Cancel
                            </GlobalButtonFinal>
                            <GlobalButtonFinal id={'saveNewClient'} className={'backgroundBlue btn-circle'}
                                               onClick={() => AddGroup('client')}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                            </GlobalButtonFinal>

                        </div>
                    </div>
                </ModalWindow>

                <ModalWindow id={'brandModal'}
                             className={` modal_dialog_small ${brandModalActive ? '' : 'hideModal'} `}>

                    <div className={'modal-header'}>

                        <h4><FormattedMessage {...messages.products.modal.addnewbrand}></FormattedMessage></h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>

                        <h5 style={{ marginTop: '5px' }}>Name</h5>
                        <input style={{ width: '97%' }} type={'text'}
                               value={inputName} onChange={onChangeHandler} />


                        {editAllType === 'brand' ?
                            <div style={{ width: '100%' }}>
                                <h5 style={{ marginTop: '15px' }}>Client</h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClient}
                                        onChange={handleParentClient}
                                >
                                    {filterClients && filterClients.length > 0 ? (
                                        <>
                                            <option value={null}>{'No client'}</option>
                                            {filterClients.map((value, index) => {
                                                return <option value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>
                            : null}


                        <div style={{ width: '25%', display: 'inline-block', marginTop: '25px' }}>
                            <h5>Upload icon</h5>
                            {editAllIcon ?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIcon} />
                                : <img style={{ border: '1px solid lightgray', maxWidth: '100%' }}
                                       src={'/images/obalero-icon.jpg'} />}

                        </div>


                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                        <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}>or choose color</span>


                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>


                        <div className={'modal-buttons'} style={{ width: '100%' }}>
                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                Cancel
                            </GlobalButtonFinal>

                            <GlobalButtonFinal className={'backgroundBlue btn-circle'}
                                               onClick={() => AddGroup('brand')}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>

                <ModalWindow id={'editionsModal'}
                             className={` modal_dialog_small ${editionsModalActive ? '' : 'hideModal'} `}>


                    <div className={'modal-header'}>

                        <h4><FormattedMessage {...messages.products.modal.addnewedition}></FormattedMessage></h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>

                        <h5 style={{ marginTop: '5px' }}>Name</h5>

                        <input style={{ width: '97%' }} type={'text'}
                               value={inputName} onChange={onChangeHandler} />

                        <div style={{ width: '48%', display: 'inline-block' }}>
                            <h5 style={{ marginTop: '15px' }}>Client</h5>
                            <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClient}
                                    onChange={handleParentClient}
                            >
                                {filterClients && filterClients.length > 0 ? (
                                    <>
                                        <option value={null}>{'No client'}</option>
                                        {filterClients.map((value, index) => {
                                            return <option value={value.uuid}>{value.name}</option>;
                                        })}
                                    </>
                                ) : null}
                            </select>
                        </div>

                        <div style={{ width: '48%', display: 'inline-block' }}>
                            <h5 style={{ marginTop: '15px' }}>Brand</h5>
                            <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentBrand}
                                    onChange={handleParentClient}
                            >
                                {filterBrands && filterBrands.length > 0 ? (
                                    <>
                                        <option value={null}>{'No brand'}</option>
                                        {filterBrands.map((value, index) => {
                                            return <option value={value.uuid}>{value.name}</option>;
                                        })}
                                    </>
                                ) : null}
                            </select>
                        </div>


                        <div style={{ width: '25%', display: 'inline-block', marginTop: '25px' }}>
                            <h5>Upload icon</h5>
                            {editAllIcon ?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIcon} />
                                : <img style={{ border: '1px solid lightgray', maxWidth: '100%' }}
                                       src={'/images/obalero-icon.jpg'} />}

                        </div>

                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                        <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}>or choose color</span>

                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>

                        <div className={'modal-buttons'} style={{ width: '100%' }}>

                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                Cancel
                            </GlobalButtonFinal>
                            <GlobalButtonFinal className={'backgroundBlue btn-circle'}
                                               onClick={() => AddGroup('edition')}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                            </GlobalButtonFinal>
                        </div>
                    </div>
                </ModalWindow>

            </ModalsWrapper>


        </>
    )
}