import React, {useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Link from 'next/link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faArrowTurnDownRight, faPenToSquare, faLock, faUnlock, faEllipsis, faPen, faRectangleVerticalHistory, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { GlobalButtonFinal } from '../../global/GlobalStyles';
import ReactTooltip from 'react-tooltip';

import ShareDialog from '../../global/menu/ShareDialog';

import { faShare } from '@fortawesome/free-solid-svg-icons';

import { getCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';

const StyledProduct = styled.div`
    min-width: 290px;
    overflow: visible;
    text-align: center;
    float: left;
    padding-bottom: 0px;
    background: white;
    //border-radius: 15px;
    margin-left: 15px;
    position: relative;
    //background:lightgray;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    .productThumbnail {
        //overflow: hidden;
        padding-top: 15px;
        padding-bottom: 15px;
        height: 250px !important;
        position: absolute !important;
        width: 100%;
        left: 0;
        img {
            width: auto;
            max-width: unset;
            max-height: 100% !important;
            float: none;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            margin-left: 5px;
            transform-origin: 25% center;
         
            transition:all 0.3s ease;
            z-index: 999999;
            &:hover{
                cursor:pointer;
                transform: translateX(-50%) scale(1.2);
            }
        }
        
      
    }
    &.withoutBG {
        .backgroundBox {
            display: none;
        }
        .gridProduct > div:nth-child(1) {
            width: 100%;
            height: 225px;
            left: 0;
            position: absolute;
            top: 46px;
            border-radius: 15px;
        }
        .productInsideShrink {
            width: 170px;
        }
    }
    &.onlyBackground {
        .productInsideShrink {
            width: 170px;
        }
        color: white !important;
        span {
            color: white !important;
        }
        p {
            color: white !important;
        }
    }
    .lockIcon {
        position: absolute;
        right: 30px;
        z-index: 99999;
        top: 30px;
    }
    &.showProduct {
        display: inline-table;
    }
    &.gridProduct {
        width: 398px;
        max-height: 300px;

        border-radius: 9px;

        justify-items: center;

        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        min-width: unset;
        padding-left: 13px;

        float: none;
        //border: 1px solid lightgray;

        margin-left: 50px;
        left: 0;
        margin-top: 50px;

        @media only screen and (max-width: 1399px) {
            width: 345px;
            margin-left: 40px;
            margin-top: 40px;
        }
        @media only screen and (max-width: 1199px) {
            width: 285px;
        }
        @media only screen and (min-width: 992px) {
            &:nth-child(3n + 1) {
                margin-left: 0px;
            }
        }

        @media only screen and (max-width: 991px) {
            width: 328px;
            margin-left: 40px;
            &:nth-child(2n + 1) {
                margin-left: 0px;
            }
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0px;
        }

        transition: all 0.3s ease;

        .productHeader {
            height: 290px;

            &.productInsideShrink {
                height: 150px;
                position: relative !important;
            }
        }

        &.productDetail {
            .productHeader {
                height: 250px;
            }
            .productDescription {
                height: 60px;
                margin-top:15px;
            }
            .productInsideShrink {
                height: 173px;
            }
        }
        .backgroundBox {
            position: absolute;
            top: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: -9;
            border-radius: 11px;
        }
    }

    .productPopup {
        display: none;
        z-index: 99999999;
        width: 220px;
        height: 242px;
        background: white;
        border-radius: 22px;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.15);
   
        margin-top: -4px;
        position: absolute;
        padding: 4px;
        left: 51px;

        &.inDetail {
            height: 206px;
        }
        &.active {
            display: block;
        }
        hr {
            margin: 0;
            margin-top: 4px;
            margin-bottom: 4px;
        }
        ul {
            list-style-type: none;
            text-align: left;
            padding: 0;
            margin: 0;

            li {
                transition: all 0.3s ease;
                font-size: 18px;
                padding: 5px;
                border-radius: 22px;
                padding-left: 15px;
                line-height: 26px;

                span {
                    transition: all 0.3s ease;
                    margin-left: 10px;
                    color: rgba(0, 0, 0, 0.6);
                    font-size: 18px;
                    font-weight: 500;
                }
                svg {
                    transition: all 0.3s ease;
                    color: rgba(0, 0, 0, 0.6);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                    span,
                    svg {
                        color: rgba(0, 0, 0, 1);
                    }
                }
                &.active {
                    color: #3d55d7;
                    span,
                    svg {
                        color: #3d55d7;
                    }
                }
            }
        }
        
        @media(max-width:600px){
            left: 50%;
            transform: translateX(-50%);
        }
    }
    

    img {
        max-width: 40%;
    }
    p,
    h3,
    span {
        padding: 0;
        margin: 0;
    }

    .productHeader {
        position: relative;
        max-height: 320px;
        height: 320px;
        transition: all 0.3s ease;
        img {
            max-height: 100%;
            transition: all 0.3s ease;
        }
        &:hover {
            img {
                transform: scale(1.05) translateX(-50%);
            }
        }
    }
    .productButtons {
        padding-bottom: 35px;
        padding-right: 15px;
        button {
            padding: 3px;
        }
    }
    .productDescription {
        height: 125px;
        position: relative;
        padding-top: 15px;
        padding-bottom: 0;
        padding-right: 15px;
        text-align: center;
        padding-left: 15px;
        border-radius: 15px;
        border-start-start-radius: 0px;
        border-start-end-radius: 0;
        &.productInsideShrink {
            height: 173px;
        }
        &.withThumbnail {
            height: auto !important;
            padding-bottom: 15px;
            padding-top: 0;
        }

        .date {
            font-size: 14px;
            font-weight: 400;
        }
        h4 {
            max-width: 100%;
            margin-top: 5px;
            font-size: 16px !important;
            margin-bottom: 10px !important;
            font-weight: 600 !important;
        }
        //h3,
        //h3:hover,
        //h3:active,
        //h3:focus{
        //
        //  text-decoration:none;
        //  font-weight: 600;
        //  padding-bottom: 5px;
        //  color: #2C2C2E;
        //  margin:0;
        //}
        p,
        p:hover,
        p:active,
        p:focus {
            color: #808080;
            text-decoration: none;
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 5px;
        }
        span,
        span:hover,
        span:active,
        span:focus {
            color: #808080;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
        }

        .productPopupButton {
            position: absolute;
            top: 18px;
            right: 25px;
            width: 17px;
            height: 30px;
            display: block;

            &.productDetail {
                top: -44px;
            }
            button {
                color: #8e8e93;
                //position: absolute;
                text-align: center;
                //right: 15px;
                //bottom: -4px;
                width: 100%;
                background: transparent;
                border: none;
                font-size: 21px;
                height: 30px;
                transition: all 0.3s ease;
                //border-radius:40px;
                padding: 0;
                opacity: 0.5;
                &:hover,
                &.active {
                    opacity: 1;
                    //  background:lightgray;
                    //  color:white;
                }
                svg {
                    //top: -1px;
                    position: relative;
                    path {
                        color: #424242;
                    }
                    width: 100%;
                    max-height: 18px;
                }
            }
        }
    }
    .productIconState {
        position: absolute;
        right: 15px;
        top: 15px;

        max-width: unset !important;
    }
    a,
    a:hover,
    a:active,
    a:focus,
    a:visited {
        color: black;
        text-decoration: none;
    }

    &.spiky {
        border-radius: 0;
    }

    &.productInsideShrink {
        width: 175px;
        margin-left: 15px !important;
        margin-top: 15px;

        border: 1px solid white;
        transition: all 0.3s ease;
        background: white;
        .whiteImage {
            opacity: 1;
            position: absolute;
            //top: 15px;
            //left: 17px;
        }
        &:hover,
        &.activeSelect {
            cusror: pointer;
            //  border: 1px solid gray;
            background: #f2f2f2;

            .whiteImage {
                opacity: 0;
            }
        }
        img{
            margin-left:0 !important;
        }
        .productDescription{
           margin-top:30px;
        }
    }
`;

const StyledThreeTumbnail = styled.div`
    width: 100%;
    height: 260px;
    display: block;

    > div:nth-child(1) {
        width: 93%;
        height: 281px;
        left: 0;
        position: absolute;
        top: 0;
        border-radius: 15px;
        @media (max-width: 8000px) {
            top: 0;
        }
    }
    &.gridProduct {
        > div:nth-child(1) {
            width: 100%;
            height: 320px;
            left: 0;
            position: absolute;
            top: 0;
            border-radius: 15px;
        }
    }

    &.productDetail {
        > div:nth-child(1) {
            position: absolute;
            border-radius: 15px;
            left: 0;
            position: absolute;
            top: 0;
            border-radius: 15px;
            width: 100%;
            height: 300px;
        }
    }

    canvas {
        border-radius: 15px;
    }
`;

export default function Product({
    props: any,
    clickEvent,
    productThumbnail,
    productInsideShrinkVersion,
    onlyBackground,
    openDuplicateDialogAndSetIDZ,
    onClickChooseShrinkFunction,
    productVersionsList,
    openDeleteDialogAndSetIDZ,
     openLockVersionDialogAndSetIDZ,
    loadProductDetailWithVersions,
    productShrinkSelected,
    withThumbnail,
    lastUpdatedTime,
    openMoveDialogAndSetIDZ,
    openRenameDialogAndSetIDZ,
    productInsideShrink,
    classes,
    productLocked,
    productType,
    productPackageGroup,
    productPackageLastUUID,
    productVolume,
    productDetail,
    styleInner,
    threeID,
    productName,
    productBrand,
    productVersions,
    productEdition,
    masterProductID,
    productID,
    productSelected,
    selectingMultiple,
    productClient,
}) {


    const [sharePersonPopupOpened, setSharePersonPopupOpened] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = event => {
            if (!ref.current?.contains(event.target)) {
                //     alert("Outside Clicked.");
                setSharePersonPopupOpened(false);
            }
        };

        window.addEventListener('mousedown', handleOutSideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick);
        };
    }, [ref]);

    const [loadShareDialog, setLoadShareDialog] = useState(false);

    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showShareDialogTransition, setShowShareDialogTransition] = useState(false);

    const changeShareShow = variable => {
        if (variable === true) {
            setShowShareDialog(true);

            setTimeout(function () {
                setShowShareDialogTransition(variable);
            }, 2);

            setLoadShareDialog(true);
        } else {
            setShowShareDialogTransition(false);
            setTimeout(function () {
                setShowShareDialog(variable);
            }, 500);
        }
    };



    const [allowLock, setAllowLock] = useState(true);

    const lockVersion = () => {
        var allProductsArray = [];
        setAllowLock(false);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'products/' + masterProductID + '/versions/' + productID, {
            method: 'PUT',
            body: JSON.stringify({
                locked: productLocked ? false : true,
            }),
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('products/' + masterProductID + '/versions/' + productID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                setAllowLock(true);
                loadProductDetailWithVersions();
            });
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <>
            <StyledProduct
                style={productSelected ? { border: '1px solid #3D55D7' } : {}}
                className={`${onlyBackground === 'true' ? 'onlyBackground' : ''}${onlyBackground === 'false' ? 'withoutBG' : ''} ${productShrinkSelected ? 'activeSelect' : ''} ${
                    productInsideShrink ? 'productInsideShrink' : ''
                } ${productType === 'GRID' ? 'gridProduct' : ''} ${classes} ${styleInner === 'spiky' ? 'spiky' : ''}  ${productDetail ? 'showProduct productDetail' : ''}`}
                onClick={!productInsideShrink ? e => clickEvent(e) : () => onClickChooseShrinkFunction(productID)}
                data-productid={productID} data-closemodalmenu={true}
            >
                {selectingMultiple ? <div style={{ position: 'absolute', zIndex: '10', width: '100%', height: '100%', top: '0', left: '0' }} data-productid={productID}></div> : null}

                {productDetail ? (
                    <>
                        {' '}
                        {productLocked ? (
                            <FontAwesomeIcon onClick={allowLock ? () => openLockVersionDialogAndSetIDZ(productID, masterProductID, productLocked) : null} className="lockIcon" icon={faLock} />
                        ) : (
                            <FontAwesomeIcon onClick={allowLock ? () =>  openLockVersionDialogAndSetIDZ(productID, masterProductID, productLocked)  : null} className="lockIcon" icon={faUnlock} />
                        )}
                    </>
                ) : null}

                {productThumbnail ? (
                    <div className={`${productInsideShrink ? 'productInsideShrink' : ''} productHeader productThumbnail`}>
                        <img src={productThumbnail} id={threeID} className={`  ${productType === 'GRID' ? 'gridProduct' : ''} ${productDetail ? 'productDetail' : ''}`} />
                    </div>
                ) : (
                    <div className={`${productInsideShrink ? 'productInsideShrink' : ''} productHeader productThumbnail`}>
                        <img src={'/images/thumbnail.png'} id={threeID} className={`  ${productType === 'GRID' ? 'gridProduct' : ''} ${productDetail ? 'productDetail' : ''}`} />
                    </div>
                )}

                {!productInsideShrink ? (
                    <Link href={productDetail ? '/editor/' + productID : '/editor/' + productPackageLastUUID}>
                        <div className={`${productInsideShrink ? 'productInsideShrink' : ''} productHeader`}>
                            <StyledThreeTumbnail id={threeID} className={`${productType === 'GRID' ? 'gridProduct' : ''} ${productDetail ? 'productDetail' : ''}`}></StyledThreeTumbnail>
                        </div>
                    </Link>
                ) : (
                    <>
                        {!withThumbnail ? (
                            <div className={`${productInsideShrink ? 'productInsideShrink' : ''} productHeader`}>
                                <StyledThreeTumbnail id={threeID} className={`${productType === 'GRID' ? 'gridProduct' : ''} ${productDetail ? 'productDetail' : ''}`}></StyledThreeTumbnail>
                            </div>
                        ) : null}
                    </>
                )}

                <div data-closemodalmenu={true} className={`${productInsideShrink ? 'productInsideShrink' : ''} ${withThumbnail ? 'withThumbnail' : ''} productDescription`}>
                    <h4 className={'mb-3'}>{productDetail ? 'Version ' + productVersions : productName}</h4>

                    {productClient &&
                    productBrand &&
                    productEdition &&
                    (productClient.toString().replaceAll(',', '') || productBrand.toString().replaceAll(',', '') || productEdition.toString().replaceAll(',', '')) &&
                    !productInsideShrinkVersion ? (
                        <>
                            <span>
                                {productClient.toString().replaceAll(',', '')}
                                {productBrand.toString().replaceAll(',', '') ? ' / ' + productBrand.toString().replaceAll(',', '') : ''}
                                {productEdition.toString().replaceAll(',', '') ? ' / ' + productEdition.toString().replaceAll(',', '') : ''}
                            </span>
                            {!productInsideShrink ? <br /> : null}
                        </>
                    ) : null}

                    {!productDetail && !productInsideShrinkVersion ? (
                        <span>
                            {' '}
                            {productPackageGroup} | {productVolume}
                        </span>
                    ) : null}

                    {productDetail || productInsideShrinkVersion ? null : <p>{'Versions: ' + productVersions}</p>}

                    {lastUpdatedTime ? (
                        <div className={`lastUpdated`}>
                            <span>{lastUpdatedTime}</span>
                        </div>
                    ) : null}
                </div>

                {!productInsideShrink ? (
                    <div className={'productButtons'}>
                        <Link href={productDetail ? '/editor/' + productID : '/editor/' + productPackageLastUUID}>
                            <GlobalButtonFinal className={'borderBlue btn-circle'} style={{ width: '84%', marginTop: '5px' }}>
                                <FontAwesomeIcon icon={faPen} style={{ marginRight: '13px' }} />
                                {productDetail ? 'Edit' : 'Edit'}
                            </GlobalButtonFinal>
                        </Link>

                        <GlobalButtonFinal data-closemodalmenu={true}
                            onClick={() => setSharePersonPopupOpened(sharePersonPopupOpened ? false : true)}
                            className={'borderBlue btn-circle'}
                            style={{ width: '84%', marginTop: '18px' }}
                        >
                            {/*<FontAwesomeIcon icon={faRectangleVerticalHistory} style={{   marginRight: "5px"}}/>*/}
                            <FontAwesomeIcon icon={faEllipsis} style={{ marginRight: '12px' }} /> More
                        </GlobalButtonFinal>

                        <div className={`${sharePersonPopupOpened ? 'active productPopup' : 'productPopup'} ${productDetail ? 'inDetail' : ''}  `} ref={ref}>
                            <ul>
                                <li onClick={() => changeShareShow(true)}>
                                    <FontAwesomeIcon icon={faShare} />
                                    <span>Share</span>
                                </li>
                                <hr />
                                {productDetail ? null : (
                                    <Link href={'/products/' + productID}>
                                        <li>
                                            <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                                            <span>View all versions</span>
                                        </li>
                                    </Link>
                                )}

                                <li onClick={() => openDuplicateDialogAndSetIDZ(productID, productVersionsList, productName, masterProductID)}>
                                    <FontAwesomeIcon icon={faClone} />
                                    <span>Duplicate</span>
                                </li>
                                <li onClick={() => openMoveDialogAndSetIDZ(productID)}>
                                    <FontAwesomeIcon icon={faArrowTurnDownRight} />
                                    <span>Move</span>
                                </li>
                                <li onClick={() => openRenameDialogAndSetIDZ(productID, productName)}>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                    <span>Rename</span>
                                </li>
                                <hr />
                                <li onClick={() => openDeleteDialogAndSetIDZ(masterProductID, productID)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                    <span>Delete</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                ) : null}

                {loadShareDialog ? (
                    <ShareDialog
                        // disableLoad={true}
                        slugState={productDetail ? productID : productPackageLastUUID}
                        typeEditor={false}
                        showShareDialog={showShareDialog}
                        showShareDialogTransition={showShareDialogTransition}
                        changeShareShow={changeShareShow}
                    />
                ) : null}

                <ReactTooltip />
                <div className={'backgroundBox'}></div>
            </StyledProduct>
        </>
    );
}
